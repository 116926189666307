import classNames from 'classnames';
import { enGB } from 'date-fns/locale';
import _find from 'lodash/find';
import _forEach from 'lodash/forEach';
import _indexOf from 'lodash/indexOf';
import _map from 'lodash/map';
import _take from 'lodash/take';
import { action, observable, toJS } from "mobx";
import { observer } from "mobx-react";
import moment from 'moment';
import React, { Component, Fragment } from "react";
import { Minus, Plus } from "react-feather";
import { DateRangePicker } from 'react-nice-dates';
import 'react-nice-dates/build/style.css';
import { Button, Dropdown, Icon, Input, Label, Menu, Placeholder, Ref, Segment } from "semantic-ui-react";
import { value } from '../../../0-common/store/LanguageStore';
import { emitter, EVENTS } from '../../../0-common/utils/EventEmitter';




const TIMESERIES = {
	OP : {
		LT : "lt",
		GT : "gt"
	}
}

@observer
export default class FilterBadgeView extends Component {

	@observable filterSearchBox = "";
	@observable showMore = false;

	@observable orderStartDate = null;
	@observable orderEndDate = null;

	@action
	setOrderStartDate = (startDate) => {
		this.orderStartDate = startDate;
		if(this.orderStartDate && this.orderEndDate){
			const orderEndDate = moment(this.orderEndDate).endOf('day').format();
			emitter.emit(EVENTS.FILTER.TIMESERIES.APPLY + this.props.eventContext, "OrderPlacedDate", this.orderStartDate, orderEndDate);
		}
	}

	@action
	setOrderEndDate = (endDate) => {
		this.orderEndDate = endDate;
		if(this.orderStartDate && this.orderEndDate){
			const orderEndDate = moment(this.orderEndDate).endOf('day').format();
			emitter.emit(EVENTS.FILTER.TIMESERIES.APPLY + this.props.eventContext, "OrderPlacedDate", this.orderStartDate, orderEndDate);
		}
	}

	@action
	clearOrderDate = () => {
		this.orderStartDate = null;
		this.orderEndDate = null;
		emitter.emit(EVENTS.FILTER.TIMESERIES.CLEAR + this.props.eventContext, "OrderPlacedDate");
	}

	applyFilter = (filterId, data, e) => {
		emitter.emit(EVENTS.FILTER.BADGE.APPLY + this.props.eventContext, filterId, data);
		this.stopPropagation(e);
	}

	applyOrderFilter = (filterId, data, e) => {
		emitter.emit(EVENTS.FILTER.ORDER.APPLY + this.props.eventContext, filterId, data);
		this.stopPropagation(e);
	}

	removeFilter = (filterId, data, e) => {
		emitter.emit(EVENTS.FILTER.BADGE.APPLY + this.props.eventContext, filterId, data);
	}

	stopPropagation = (e) => {
		e.stopPropagation();
	}

	toggleShowMore(show){
		this.showMore = show;
	}	

	/*executeClearFilter = () => {
		this.props.searchStore.clearSearchQuery();
		emitter.emit(EVENTS.FILTER.CLEAR + this.props.eventContext);
	}*/

	applySearchOnFilter = (optionList, e, data) =>{
		this.filterSearchBox = data.value;
		_forEach(optionList, (options) => {
			if(options.text.toLowerCase().indexOf(data.value.toLowerCase()) > -1){
				options.hidden = false;
			} else {
				options.hidden = true;
			}
		});
	}

	selectFilterSearch = (e) => {
		this.stopPropagation(e);
	}

	resetFilterSearchBox = (optionList) => {
		this.applySearchOnFilter(optionList, null, {value: ""});
	}


	buildCategoryTreeInfo(categoryOptions){
		let bottomBorderNoneList = [];
		let topBorderList = [];
		let lastProcesseedOption = categoryOptions.length > 1 ? categoryOptions[0] : {level : 0};

		_forEach(categoryOptions, (currentOption) => {
			if(lastProcesseedOption.level > currentOption.level){
				bottomBorderNoneList.push(lastProcesseedOption.key);
				topBorderList.push(currentOption.key);
			}
			lastProcesseedOption = currentOption;
		});
		return {bottomBorderNoneList, topBorderList};
	}

	buildFilters(){
		const filters = this.props.filterStore.filterList;
		const appliedFilters = this.props.filterStore.appliedFilter;

		return _map(filters, (filter) => {
			const appliedFilterOptions = appliedFilters.get(filter.uid);
			
			const selectedFilterClassList = classNames({
		      	'selectedFilter': (appliedFilterOptions ? true : false),
		      	'link': true,
		      	'item': true
		    });
			
			const borderInfo = this.buildCategoryTreeInfo(filter.options);

			return (
				<div key={filter.uid} className="filter" >
				    <Dropdown
				    	text={filter.text} 
				    	pointing="top left" 
				    	className={selectedFilterClassList}
				    	disabled={!filter.isAvailable}
				    	onClose={this.resetFilterSearchBox.bind(this, filter.options)} >
						    <Dropdown.Menu
						    	onClick={this.stopPropagation} >
						    	{
						    		filter.options.length > 1
						    		&&
						    		<Input
							      		value={this.filterSearchBox}
							      		placeholder='Search...' 
							      		icon='search' 
							      		iconPosition='left' 
							      		className='search'
							      		onClick={this.selectFilterSearch}
							      		onChange={this.applySearchOnFilter.bind(this, filter.options)} />
						    	}
						        <Dropdown.Header>{filter.text}</Dropdown.Header>
						        <Dropdown.Menu scrolling >
							        {	
							        	_map(filter.options, (filterOption) => {
							        		if(filterOption.hidden)
							        			return;
											const optionFilterClassList = classNames({
										      	'filterOption': true,
										      	'categoryOption' : (filter.uid === "Categories"),
										      	'Level1' : (filterOption.level === 1),
										      	'Level2' : (filterOption.level === 2),
										      	'Level3' : (filterOption.level === 3),
										      	'Level4' : (filterOption.level === 4),
										      	'bottomBorderNone' : (_indexOf(borderInfo.bottomBorderNoneList, filterOption.key) !== -1),
										      	'topBorder' : (_indexOf(borderInfo.topBorderList, filterOption.key) !== -1)
										    });
							        		const isApplied = (_indexOf(appliedFilterOptions, filterOption.value) !== -1);
							        		return (
							        			<Fragment key={filterOption.key}>
								        			<Dropdown.Item 
									        			key={filterOption.key}
									        			selected={isApplied} 
									        			disabled={!filterOption.isAvailable} 
									        			onClick={this.applyFilter.bind(this, filter.uid, filterOption.value)}
									        			className={optionFilterClassList} >
									        				
								        					<div style={{marginRight: "0.5rem", padding: "0.5rem"}}>{filterOption.text}</div>
								        					<Label className="filterOptionCount">{filterOption.count}</Label>
								        			</Dropdown.Item>
												</Fragment>
							        		);
							        	})
							        }
							    </Dropdown.Menu>        
						    </Dropdown.Menu>
					</Dropdown> 
				</div>
			);
		});
	}

	buildFilterBadgeView(){
		const allowedFilterCount = (window.screen.width > 1439) ? 9 : 8;
		const filterList = this.buildFilters()
		let finalFilterList = null;
		if(filterList.length > allowedFilterCount && this.showMore === false){
			finalFilterList = _take(filterList, allowedFilterCount);
			const label = value('lb_filter_showmore', (filterList.length - allowedFilterCount));
			finalFilterList.push(<div key="showMore" onClick={this.toggleShowMore.bind(this, true)} className="filter extra"> <Plus size="14"/> {label} </div>)
		} else {
			finalFilterList = filterList;
			if(this.showMore){
				const label = value('lb_filter_showless');
				finalFilterList.push(<div key="showLess" onClick={this.toggleShowMore.bind(this, false)} className="filter extra"> <Minus size="14"/> {label} </div>)
			}	
		}
		
		return finalFilterList;
	}

	buildFilterOrderView(){
		const isLoading = this.props.filterStore.isLoading;
		if(isLoading){
			return(
				<div style={{display: "flex", justifyContent: "space-between"}}>
					<div style={{display: "flex", width: "80%"}}>
						<Segment basic style={{margin: "0", padding: "0", width: "10%"}}>
							<Placeholder>
								<Placeholder.Line length='medium' />
								<Placeholder.Line length='medium' />
							</Placeholder>
						</Segment>
					</div>
					<div style={{display: "flex", width: "20%"}}>
						<Segment basic style={{margin: "0", padding: "0", width: "50%"}}>
							<Placeholder>
								<Placeholder.Line length='medium' />
								<Placeholder.Line length='medium' />
							</Placeholder>
						</Segment>
						<Segment basic style={{margin: "0", padding: "0", width: "50%"}}>
							<Placeholder>
								<Placeholder.Line length='medium' />
								<Placeholder.Line length='medium' />
							</Placeholder>
						</Segment>
					</div>
				</div>
			);
		} else {
			const orderFilterView = this.buildFilterBadgeView();
			return(
				<div style={{display: "flex", justifyContent: "space-between", height: "var(--block-height)"}}>
					{
						orderFilterView
					}
					{
						orderFilterView
						&&
						orderFilterView.length > 0
						&&
						<Menu secondary style={{margin: "0", fontSize: "1.10rem"}}>
							<Menu.Menu position='right' className="orderTimeMenu">
								<Menu.Item>
									<DateRangePicker
									startDate={this.orderStartDate}
									endDate={this.orderEndDate}
									onStartDateChange={this.setOrderStartDate}
									onEndDateChange={this.setOrderEndDate}
									minimumLength={1}
									format='dd MMM yyyy'
									locale={enGB}
									>
									{({ startDateInputProps, endDateInputProps, focus }) => (
										<div className='date-range'>
											<Ref innerRef={startDateInputProps.ref}>
												<Input icon iconPosition='left' size="mini" {...startDateInputProps} placeholder='Start date' >
													<Icon name='calendar alternate outline'/>
													<input readOnly={true} />
												</Input>
											</Ref>
											
											<span style={{margin: "0 1rem"}}>To</span>
											
											<Ref innerRef={endDateInputProps.ref}>
												<Input icon iconPosition='left' size="mini" {...endDateInputProps} placeholder='End date' >
													<Icon name='calendar alternate outline'/>
													<input readOnly={true} />
												</Input>
											</Ref>
											
										</div>
									)}
									</DateRangePicker>
								</Menu.Item>
								<Menu.Item>
									<div>
										<Button style={{background: "var(--secondary-color)"}} content="Clear" disabled={!this.orderEndDate && !this.orderEndDate} onClick={this.clearOrderDate} />
									</div>
								</Menu.Item>
							</Menu.Menu>
						</Menu>
					}
				</div>
			);
		}
		
	}

	buildMsgView(){
		const appliedFilters = toJS(this.props.filterStore.appliedFilter);
		const filterList = this.props.filterStore.filterList;
		if(filterList.length > 0){
			return	_map(appliedFilters, (appliedFilterOptions, filterId) => {
						const filterRef = _find(filterList, ["uid", filterId]);
						return _map(appliedFilterOptions, (appliedOptionValue) =>{
							const filterOptionsRef = _find(filterRef.options, ["value", appliedOptionValue]);		
							return (
								<Label 
									key={filterId + "#" + appliedOptionValue} 
									basic 
									className="selectedFilter">
										  {filterOptionsRef.text}
										  <Icon 
											  name='delete' 
											  onClick={this.removeFilter.bind(this, filterId, appliedOptionValue)} />
								</Label>
							);
						});
					});
		}
	}

	render() {
		let filterView = null;
		let filterViewClassList = null;
		let filterMsgView = null;

		const filterViewType = this.props.viewType;
		switch (filterViewType) {
			case "BADGE": 
				filterView = this.buildFilterBadgeView();
				filterViewClassList = classNames('filterView', 'badgeView');
				filterMsgView = this.buildMsgView();
				break;
			
			case "ORDER": 
				filterView = this.buildFilterOrderView();
				filterViewClassList = classNames('filterView', 'orderView');
				filterMsgView = null;
				break;
		}

		return (
			<div className="filterBar">
				<Segment basic className={filterViewClassList}>
					{filterView}
				</Segment>
				<div className="selectedFilterView">
					{filterMsgView}
				</div>
			</div>
		);
	}
}