
import { createMedia } from "@artsy/fresnel"

const Responsive = createMedia({
    breakpoints: {
        mobile: 0,
        tablet: 768,
        laptop: 1024,
        desktop: 1140,
    },
    interactions: {
        hover: "(hover: hover)",
        notHover: "(hover: none)",
        landscape: "not all and (orientation: landscape)",
        portrait: "not all and (orientation: portrait)",
    },
});
export const mediaStyle = Responsive.createMediaStyle();
export const { Media, MediaContextProvider, createMediaStyle } = Responsive;