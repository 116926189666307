import React from "react";

const contextRepository = {};
const htmlRefRepository = {};

const useScroll = (context = "default") => {
	let scrollContext = contextRepository[context];
	if(!scrollContext){

		const htmlElRef = (refId, ref) => {
			htmlRefRepository[refId] = ref;
		};

		const executeScroll = (refId) => {
		    //window.scrollTo(0, htmlRefRepository[refId].offsetTop);
		    htmlRefRepository[refId].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
		};

		scrollContext = {executeScroll, htmlElRef};
	}
	return scrollContext;
}

export default useScroll;