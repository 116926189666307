import { action, computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Icon, Search } from 'semantic-ui-react';
import { value } from '../store/LanguageStore';
import { emitter, EVENTS } from '../utils/EventEmitter';

@observer
export default class SearchView extends Component {

  @observable showNoResults = false;

  @computed
  get showSearchResult(){
    return (!this.props.searchStore.isLoading && this.props.searchStore.query !== "");
  }

  @action
  handleClearSearch = (e) => {
    if(this.props.searchStore.finalSearchQuery === ""){
      // if search was never applied just clear all the flags
      this.props.searchStore.clearSearchQuery();
      this.props.searchStore.results = [];
      this.showNoResults = false;
    } else {
      // if search was applied clear all the flags + trigger empty search to fetch fresh data.
      this.props.searchStore.clearSearchQuery();
      this.props.searchStore.results = [];
      this.showNoResults = false;
      this.props.searchStore.searchApplied = true;
      emitter.emit(EVENTS.SEARCH.APPLY+this.props.eventContext);
    }
    e.stopPropagation();
  }

  handleSearchChange = (e, { value }) => {
    console.log("Firing Search Change.....");
    this.showNoResults = true;
    const filterQuery = this.props.filterStore.getFilterQuery();
    this.props.searchStore.fireSearch(value, filterQuery);
  }

  @action
  handleResultSelect = (e, { result }) => {
    if(this.props.searchInPopUpContainer){
      //Added for AddItemContainer > Search call;
      emitter.emit(EVENTS.ITEM.SELECT+this.props.eventContext, EVENTS.ITEM.SELECT, result.uid, result.type, result.category);
      emitter.emit(EVENTS.SEARCH.APPLY+this.props.eventContext);
    } else {
      emitter.emit(EVENTS.ITEM.OPEN+this.props.eventContext, EVENTS.ITEM.OPEN, result.uid, result.type, result.category);
    }
    this.props.searchStore.clearSearchQuery();
    this.props.searchStore.expandSearchBox = false;
  }

  /*@action.bound
  handleOnFocusOut(e){
    this.props.searchStore.query = "";
    this.showNoResults = false;
    this.props.searchStore.results = [];
    this.props.searchStore.searchApplied = false;
  }*/

  @action.bound
  handleEnterKeyPress(e){
    if(e.key === "Enter"){
      if(this.props.searchStore.results.length > 0){
        this.props.searchStore.setSearchQuery();
        this.props.searchStore.results = [];
        this.showNoResults = false;
        emitter.emit(EVENTS.SEARCH.APPLY+this.props.eventContext);
        this.props.searchStore.searchApplied = true;
        this.props.searchStore.expandSearchBox = false;
      }
    }
  }

  render() {
    const isLoading = this.props.searchStore.isLoading;
    const results = this.props.searchStore.results;
    const searchQuery = this.props.searchStore.query;

    return (
      <Search
          fluid
          input={
            searchQuery === "" ?
            { size: this.props.size, placeholder: "Search . . .", icon: 'search', iconPosition: 'left'} :
            { size: this.props.size, icon: <Icon name='remove circle' link onClick={this.handleClearSearch} /> } 
          }
          loading={isLoading}
          showNoResults={this.showNoResults}
          onResultSelect={this.handleResultSelect}
          onSearchChange={this.handleSearchChange}
          results={results}
          value={searchQuery}
          onKeyPress={this.handleEnterKeyPress}
          autoFocus
          style={{border: "none"}}
          open={this.showSearchResult}
          noResultsMessage={value('msg_noresultfound')}
          disabled={this.props.searchStore.isDisabled} // Added for HeaderTemplateWrapper
          />
    );
  }

}