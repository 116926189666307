import classNames from 'classnames';
import _capitalize from 'lodash/capitalize';
import _forEach from 'lodash/forEach';
import _isUndefined from 'lodash/isUndefined';
import _map from 'lodash/map';
import _split from 'lodash/split';
import { action, observable } from 'mobx';
import React, { Component, Fragment } from 'react';
import { Button, Container, Dropdown, Header, Icon, Image, Modal, Segment } from "semantic-ui-react";
import ItemCardView from '../../app/view/Item-CardView';
import ItemListView from '../../app/view/Item-ListView';
import ItemTableView from '../../app/view/Item-TableView';
import CustomerTableView from '../../app/view/Customer-TableView';
import ConfirmDelete from '../../app/view/special/ConfirmDelete';
import FilterBadgeView from '../../app/view/special/Filter-BadgeView';
import SortView from '../../app/view/special/SortView';
import { number, value } from '../store/LanguageStore';
import { ph_limitReachedImageURL } from '../utils/DefaultImage';
import { emitter, EVENTS } from '../utils/EventEmitter';
import VerticalFactory from '../utils/vertical/VerticalFactory';
import SearchView from '../view/SearchView';



export default class ItemContainer extends Component {

	emitterBindList = {};
	@observable containerView = "CardView";
	@observable showTutorial = false;
	//param1 - standard subject type based on modules
	//param2 - subject type based on relationship (Predicate)
	constructor(props, STORE_SUBJECT_TYPE, X_SUBJECT_TYPE){
		super(props);
		this.itemStore = props.storeFactory.fetchStore(STORE_SUBJECT_TYPE, X_SUBJECT_TYPE);
		// Commented below 2 lines for 'search on enter' to work in item open container.
		//this.itemStore.filterStore.initialize();
		//this.itemStore.searchStore.clearSearchQuery();
		this.containerContextName = this.getContextName(props, STORE_SUBJECT_TYPE);
		this.predicateContext = props.predicate ? '#'+props.predicate : "";
		this.STORE_TYPE = STORE_SUBJECT_TYPE;
		this.bindEvents(props);
	}

	bindEvents = (props) => {
		
		/*this.emitterBindList.push(emitter.on(EVENTS.ITEM.CREATE.OPEN+this.predicateContext, this.eventContextProcessor.bind(this, this.handleCreateItemActions) ));
		this.emitterBindList.push(emitter.on(EVENTS.ITEM.CREATE.CANCEL+this.predicateContext, this.eventContextProcessor.bind(this, this.handleCreateItemActions) ));
		this.emitterBindList.push(emitter.on(EVENTS.ITEM.CREATE.DONE+this.predicateContext, this.eventContextProcessor.bind(this, this.handleCreateItemActions) ));*/

		this.emitterBindList[EVENTS.ITEM.OPEN+this.predicateContext] = emitter.on( EVENTS.ITEM.OPEN+this.predicateContext, this.eventContextProcessor.bind(this, this.handleItemActions) );
		this.emitterBindList[EVENTS.ITEM.SELECT+this.predicateContext] = emitter.on( EVENTS.ITEM.SELECT+this.predicateContext, this.eventContextProcessor.bind(this, this.handleItemActions) );
		this.emitterBindList[EVENTS.ITEM.DELETE+this.predicateContext] = emitter.on( EVENTS.ITEM.DELETE+this.predicateContext,  this.confirmAction.bind(this, this.eventContextProcessor.bind(this, this.handleItemActions)) );
		this.emitterBindList[EVENTS.ITEM.CLONE+this.predicateContext] = emitter.on( EVENTS.ITEM.CLONE+this.predicateContext, this.handleCloneAction );
		/*
			since the call is triggered from Itemcontainer class itself no need to go via event.
			this.emitterBindList.push(emitter.on(EVENTS.ITEM.DELETE_BULK+this.predicateContext, this.eventContextProcessor.bind(this, this.handleItemActions) ));
		*/	

		this.emitterBindList[EVENTS.PAGE.PAGINATE+this.predicateContext] = emitter.on( EVENTS.PAGE.PAGINATE+this.predicateContext, this.handlePaginationChange );
		this.emitterBindList[EVENTS.SEARCH.APPLY+this.predicateContext] = emitter.on( EVENTS.SEARCH.APPLY+this.predicateContext, this.handleSearchApply );
		this.emitterBindList[EVENTS.FILTER.BADGE.APPLY+this.predicateContext] = emitter.on( EVENTS.FILTER.BADGE.APPLY+this.predicateContext, this.handleApplyBadgeFilter );
		this.emitterBindList[EVENTS.FILTER.BADGE.CLEAR+this.predicateContext] = emitter.on( EVENTS.FILTER.BADGE.CLEAR+this.predicateContext, this.handleApplyBadgeFilterClear);
		this.emitterBindList[EVENTS.FILTER.ORDER.APPLY+this.predicateContext] = emitter.on( EVENTS.FILTER.ORDER.APPLY+this.predicateContext, this.handleApplyOrderFilter );
		this.emitterBindList[EVENTS.FILTER.TIMESERIES.APPLY+this.predicateContext] = emitter.on( EVENTS.FILTER.TIMESERIES.APPLY+this.predicateContext, this.handleApplyTimeSeriesFilter );
		this.emitterBindList[EVENTS.FILTER.TIMESERIES.CLEAR+this.predicateContext] = emitter.on( EVENTS.FILTER.TIMESERIES.CLEAR+this.predicateContext, this.handleClearTimeSeriesFilter );
		/*this.emitterBindList.push(emitter.on(EVENTS.FILTER.APPLY+this.predicateContext, this.handleFilterApply));
		this.emitterBindList.push(emitter.on(EVENTS.FILTER.CLEAR+this.predicateContext, this.handleFilterClear));
		this.emitterBindList.push(emitter.on(EVENTS.FILTER.TOGGLE+this.predicateContext, this.handleFilterViewToggle));
		this.emitterBindList.push(emitter.on(EVENTS.FILTER.VALUECHANGE+this.predicateContext, this.handleFilterChange));*/
		this.emitterBindList[EVENTS.SORT.APPLY+this.predicateContext] = emitter.on( EVENTS.SORT.APPLY+this.predicateContext, this.handleApplySort );
		

		this.emitterBindList[EVENTS.ACTION.SELECTALL+this.predicateContext] = emitter.on( EVENTS.ACTION.SELECTALL+this.predicateContext, this.eventContextProcessor.bind(this, this.handleOnAction) );
		this.emitterBindList[EVENTS.ACTION.DESELECTALL+this.predicateContext] = emitter.on( EVENTS.ACTION.DESELECTALL+this.predicateContext, this.eventContextProcessor.bind(this, this.handleOnAction) );	
		//this.emitterBindList[EVENTS.ACTION.REFRESH+this.predicateContext] = emitter.on( EVENTS.ACTION.REFRESH+this.predicateContext, this.eventContextProcessor.bind(this, this.handleOnAction) );
		this.emitterBindList[EVENTS.ACTION.REFRESH+this.predicateContext] = emitter.on( EVENTS.ACTION.REFRESH+this.predicateContext, this.fetchListViewData.bind(this) );

		this.emitterBindList[EVENTS.WORKFLOW.ACTION.EXECUTE+this.predicateContext] = emitter.on( EVENTS.WORKFLOW.ACTION.EXECUTE+this.predicateContext, this.eventContextProcessor.bind(this, this.handleWorkflowAction) );	
	}

	unBindEvents = () => {
		_forEach(this.emitterBindList, (unbindRef, key) => {
			unbindRef();
		})
		this.emitterBindList = {};
	}

	get CommonActions(){
		return ['SEARCH', 'FILTER'];
	}

	get SpecialActions(){
		return [];
	}

	get ItemActions(){
		return ['SELECT'];
	}

	get BulkActions(){
		return [];
	}

	get ItemsPerRow(){
		return 3;
	}	

	get SelectOnClick(){
		return false;
	}	

	get ShowCollageView(){
		return false;
	}

	get FilterViewType(){
		return "BADGE";
	}

	get ShowItemStock(){
		const vertical = process.env.vertical;
        const verticalConfig = VerticalFactory.fetchVertical(vertical);
		const inventoryInfo = verticalConfig.getInventoryConfig();
		if(inventoryInfo){
			return inventoryInfo.visible;
		} else {
			return true;
		}
	}

	get ExcludeSortOptions(){
		return [];
	}

	get CustomSortOptions(){
		return [];
	}

	getContextName(props, STORE_SUBJECT_TYPE){
		let contextName = props.storeFactory.ModuleStore.getModule(STORE_SUBJECT_TYPE) ? props.storeFactory.ModuleStore.getModule(STORE_SUBJECT_TYPE).Name : "";
		if(props.predicateName)
			contextName = props.predicateName;
		return contextName;
	}

	setContainerView = (containerViewName) => {
		this.containerView = containerViewName;
	}

	isContainerEmpty = () => {
		if(this.itemStore.isLoading){
			return false;
		} else {
			const itemsCount = this.itemStore.SubjectCounts[this.STORE_TYPE] || 0;
			if(itemsCount === 0){
				return true;
			}
			return false;
		}
	}
	
	//************** Action Handlers ***********************

	eventContextProcessor = (eventHandler, ...args) => {
		const actionkey = _split(args[0], '#')[0];
		args[0] = actionkey;
		eventHandler(...args);
	}

	confirmAction = function(pendingAction) {
		let args = [].slice.call(arguments, 1);
		this.itemStore.deleteStatus.confirmDelete = true;
		this.itemStore.deleteStatus.pendingAction = pendingAction.bind(this, ...args);
		
	}

	@action.bound
	handleConfirmDelete(){
		this.itemStore.deleteStatus.confirmDelete = false;
		this.itemStore.deleteStatus.pendingAction();
	}

	@action.bound
	handleCancelDelete(){
		this.itemStore.deleteStatus.confirmDelete = false;
	}

	handleItemActions = (mode, itemId, itemType, itemCategory, itemModule) => {
		this.itemStore.executeItemAction(mode, itemId, itemType, itemCategory, itemModule);
	}

	handleCreateItemActions = (mode, isPredicateCreate, predicate) => {	
		this.itemStore.executeCreateItemAction(mode, isPredicateCreate, predicate);
	}

	handleOnAction = (actionName) => {
		this.itemStore.executeAction(actionName);
	}

	handlePaginationChange = (activePage) => {
		this.executePagination(activePage);
	}

	//TO_BE Overriden by Extneding Predicate Container 
	executePagination = (activePage) => {
		this.props.storeFactory.NavigationStore.setActviePage(activePage);
		this.itemStore.executePagination(activePage);
	}

	/*handleFilterChange = (filterName, filterValue) => {
		this.itemStore.filterStore.saveSelection(filterName, filterValue);
	}

	handleFilterApply = () => {
		//this.props.filterStore.loadFilters();
		this.itemStore.filterStore.applySelection();
		this.props.storeFactory.NavigationStore.setActviePage(1);
		this.fetchListViewData();
	}
	
	handleFilterClear = () => {
		this.itemStore.filterStore.clear();
		this.props.storeFactory.NavigationStore.setActviePage(1);
		this.fetchListViewData();
	}
	
	handleFilterViewToggle = () => {
		this.itemStore.filterStore.toggleView();
	}*/

	handleApplySort = (sortOption) => {
		this.itemStore.filterStore.ActiveSortOption = sortOption;
		this.props.storeFactory.NavigationStore.setActviePage(1);
		this.fetchListViewData();
	}

	handleApplyBadgeFilterClear = () => {
		this.itemStore.filterStore.clear();
		this.props.storeFactory.NavigationStore.setActviePage(1);
		this.fetchListViewData();
	}

	handleApplyBadgeFilter = (badgeFilterId, selectedBadge) => {
		this.itemStore.filterStore.applyFilterSelection(badgeFilterId, selectedBadge);
		this.props.storeFactory.NavigationStore.setActviePage(1);
		this.fetchListViewData();
	}

	handleApplyOrderFilter = (filterId, selectedOrderStatus) => {
		this.itemStore.filterStore.toggleFilterSelection(filterId, selectedOrderStatus);
		this.props.storeFactory.NavigationStore.setActviePage(1);
		this.fetchListViewData();
	}

	handleApplyTimeSeriesFilter = (filterId, selectedStartDateTime, selectedEndDateTime) => {
		this.itemStore.filterStore.applyTimeSeriesFilter(filterId, selectedStartDateTime, selectedEndDateTime);
		this.props.storeFactory.NavigationStore.setActviePage(1);
		this.fetchListViewData();
	}

	handleClearTimeSeriesFilter = (filterId) => {
		this.itemStore.filterStore.clearTimeSeriesFilter(filterId);
		this.props.storeFactory.NavigationStore.setActviePage(1);
		this.fetchListViewData();
	}

	handleSearchApply = () => {
		this.itemStore.searchStore.isOpen = false;
		this.props.storeFactory.NavigationStore.setActviePage(1);
		this.fetchListViewData();
	}

	handleCloneAction = (itemIds) => {
		this.itemStore.clone(itemIds);
	}

	handleWorkflowAction = (actionName, objectID, patch) => {
		this.itemStore.executeWorkflowActionAndFind(actionName, objectID, this.getActivePage(), patch);
	}

	executeCreateActionDone = () => {
		emitter.emit(EVENTS.ITEM.CREATE.DONE, EVENTS.ITEM.CREATE.DONE+this.predicateContext, false);
	}

	executeCreateActionCancel = () => {
		emitter.emit(EVENTS.ITEM.CREATE.CANCEL, EVENTS.ITEM.CREATE.CANCEL+this.predicateContext);
	}

	executeAddToCollectionAction = function(){
		emitter.emit(EVENTS.ITEM.ADDTOCOLLECTION.OPEN, EVENTS.ITEM.ADDTOCOLLECTION.OPEN);
	}

	executeAddToCatalogAction = function(){
		emitter.emit(EVENTS.ITEM.ADDTOCATALOG.OPEN, EVENTS.ITEM.ADDTOCATALOG.OPEN);
	}

	executeBulkRemoveAction = function(eventContext, predicate, selectedItems, selectedItemsRef){
		emitter.emit(EVENTS.PREDICATE.REMOVEITEM.START+eventContext, predicate, selectedItems, selectedItemsRef);
	}

	executeBulkDeleteAction = () => {
		//emitter.emit(EVENTS.ITEM.DELETE_BULK+eventContext, predicate, selectedItems);
		const itemsToBeDeleted = this.itemStore.getSelectedItems().selectdItem;
		this.itemStore.bulkDeleteItem(this.itemStore.SubjectInfo.Type, itemsToBeDeleted);
	}

	executeClearSelectionAction = function(eventContext){
		emitter.emit(EVENTS.ACTION.DESELECTALL+eventContext, EVENTS.ACTION.DESELECTALL);
	}

	executeSpecialAction = function(eventName){
		emitter.emit(eventName, eventName);
	}

	executeSpecialMoveAction = function(moveMode, eventContext){
		emitter.emit(EVENTS.PREDICATE.MOVEITEM+eventContext, moveMode);
	}

	executeBulkCloneAction = function(eventContext, selectedItems){
		emitter.emit(EVENTS.ITEM.CLONE+eventContext, selectedItems);
	}

	//************** Service Handlers ***********************

	async fetchListViewData(){
		await this.itemStore.loadItems(this.getActivePage());	
	}

	resetStatus = () => {
		this.itemStore.toggleAllSelectionTo(false);
		this.itemStore.resetSecActionStatus();
	}

	getActivePage(){
		return this.props.storeFactory.NavigationStore.getActviePage();//Pagination
	}

	resetSortOption = () => {
		this.itemStore.filterStore.resetActiveSortOption();
	}

	
	/* Common Action Container ############## */

	onShowTutorial = () => {
		this.showTutorial = true;
	}

	onHideTutorial = () => {
		this.showTutorial = false;
	}

	getSearchView = (advance = false) =>{
		return(
			<Segment className="searchBar" key="searchBar">
				<SearchView
					size="big" 
				    searchStore={this.itemStore.searchStore}
				    filterStore={this.itemStore.filterStore}
					eventContext={this.predicateContext}
					searchInPopUpContainer={advance} />
				{
					/*this.renderHelpView
					&&
					this.renderHelpView()*/
				}
			</Segment>
		);
	}

	getFilterView = () =>{
		return(
			<FilterBadgeView
				viewType={this.FilterViewType}
				filterStore={this.itemStore.filterStore}
				searchStore={this.itemStore.searchStore}
				containerContextName={this.containerContextName}
				eventContext={this.predicateContext}  />
		);
	}

	getSortView = () =>{
		return(
			<SortView 
			filterStore={this.itemStore.filterStore} 
			eventContext={this.predicateContext} 
			excludeSortOptions={this.ExcludeSortOptions} 
			customSortOptions={this.CustomSortOptions} />
		);
	}

	getSpecialActionView(){
		const specialActionList = this.SpecialActions;
		const specialActionView = _map(specialActionList, (specialActionName) => {
			switch(specialActionName){

				case 'ADD_EXISTING' : return (
										<Button
											primary
											labelPosition='left'
											icon='add'
											content={value('bt_addexisting')}
											size="small"
											key={specialActionName}
											onClick={this.executeSpecialAction.bind(null, EVENTS.PREDICATE.ADDITEM.OPEN+this.predicateContext)} />
									);

				case 'MOVE UP' 		: return (
										<Button
											icon='angle up'
											key={specialActionName}
											onClick={this.executeSpecialMoveAction.bind(null, "UP", this.predicateContext)} />
									);

				case 'MOVE DOWN' 	: return (
										<Button
											icon='angle down'
											key={specialActionName}
											onClick={this.executeSpecialMoveAction.bind(null, "DOWN", this.predicateContext)} />
									);

				case 'MOVE TOP' 	: return (
										<Button
											icon='angle double up'
											key={specialActionName}
											onClick={this.executeSpecialMoveAction.bind(null, "TOP", this.predicateContext)} />
									);

				case 'MOVE BOTTOM' 	: return (
										<Button
											icon='angle double down'
											key={specialActionName}
											onClick={this.executeSpecialMoveAction.bind(null, "BOTTOM", this.predicateContext)} />
									);
				
				case 'REFRESH' 		: return (
										<Button
											secondary
											labelPosition='left'
											icon='sync'
											content="Refresh"
											size="small"
											key={specialActionName}
											onClick={this.executeSpecialAction.bind(null, EVENTS.ACTION.REFRESH+this.predicateContext)} />
									);
			}

		});

		return (
			<Segment basic className="specialActionView">
				{specialActionView}
			</Segment>
		)
	}

	getBulkActionView = () => {
		
		const bulkActionList = this.BulkActions;
		
		const secondaryActionStatus = this.itemStore.secondaryActionStatus;
		const selectedItems = this.itemStore.getSelectedItemObj();
		const selectedItemCount = selectedItems.length;

		const collectionCount = this.itemStore.SubjectCounts.COLLECTIONS || 0;
		const catalogCount = this.itemStore.SubjectCounts.CATALOGS || 0;
		return(
			(secondaryActionStatus.isAvailable) && 
			(selectedItemCount > 0) &&
			<Segment basic className="bulkActionView">
				<div style={{whiteSpace: "nowrap"}}>{number(selectedItemCount) + " " + value('lb_item_selected')}</div>
				<Dropdown 
					icon="ellipsis vertical" 
					className="link item selectionMoreMenu"
					pointing="top right" >
					    <Dropdown.Menu>
					        {
								bulkActionList.map((actionName) => {		
									switch(actionName) {

										case 'ADD_TO_COLLECTION' 	: 	return (
																			<Dropdown.Item
																				key={actionName} 
													        					icon="folder open"
													        					text={value('bt_menu_addtocollection')}
													        					disabled={collectionCount === 0}
													        					onClick={this.executeAddToCollectionAction} />
													        			);
										case 'ADD_TO_CATALOG' 		: 	return (
																			<Dropdown.Item
																				key={actionName}  
															        			icon="map"
															        			text={value('bt_menu_addtocatalog')}
															        			disabled={catalogCount === 0}
															        			onClick={this.executeAddToCatalogAction} />
																		);
										case 'DELETE' 				: 	return (
																			<Dropdown.Item
																				key={actionName}  
															        			icon="trash alternate"
															        			text={value('bt_menu_delete')}
															        			onClick={this.confirmAction.bind(this, this.executeBulkDeleteAction)}  /> 
																		);
										case 'REMOVE'				: 	return (
																			<Dropdown.Item
																				key={actionName}  
															        			icon="remove circle"
															        			text={value('bt_menu_remove')}
															        			onClick={this.executeBulkRemoveAction.bind(null, this.predicateContext, this.props.predicate, this.itemStore.getSelectedItems().selectdItem, this.itemStore.getSelectedItems().selectedItemsRef)} /> 
																		);
										case 'CLONE'				: 	return (
																			<Dropdown.Item
																				key={actionName}  
															        			icon="copy"
															        			text={value('bt_menu_clone')}
															        			onClick={this.executeBulkCloneAction.bind(null, this.predicateContext, this.itemStore.getSelectedItems().selectdItem)} /> 
																		);
									}
								})
					        }
					        <Dropdown.Item
								key="clear"  
			        			icon="check circle outline"
			        			text={value('bt_menu_clearselection')}
			        			onClick={this.executeClearSelectionAction.bind(null, this.predicateContext)}/>    
					    </Dropdown.Menu>
				</Dropdown>
			</Segment>
		);	
	}
	/* Common Action Container */


	/* Special Action Container ############## */

	/*getCreateContainer(){
		return (
			<Modal
				key="CreateContainerModal"
				size='tiny'
			    className='CreatePopup'
			    open={this.props.createStore.isCreateViewOpen}
			    closeOnDimmerClick={false} >
			    	<Header content={value('lb_createnew')} />
			    	<Modal.Content>
				      <CreateView typeInfo={this.typeInfo} />
				    </Modal.Content>
				    <Modal.Actions>
				      <Button primary onClick={this.executeCreateActionDone}>
				        <Icon name='add' /> {value('bt_create')}
				      </Button>
				      <Button onClick={this.executeCreateActionCancel}>
				        <Icon name='close' /> {value('bt_close')}
				      </Button>
				    </Modal.Actions>
			</Modal>	
		);
	}*/

	getAddExistingContainer(){
		return null;
	}
	/* Special Action Container */

	/* Item Action Container ############## */

	getAddToCollectionContainer(){
		return null;
	}

	getAddToCatalogContainer(){
		return null;
	}

	getDeleteContainer(){
		return (
			<ConfirmDelete
				key="confirmDelete"
				deleteStatus={this.itemStore.deleteStatus}
				store={this.itemStore}
				context={this.containerContextName}
				onConfirm={this.handleConfirmDelete}
				onCancel={this.handleCancelDelete} />
		);
	}

	getCloneContainer(){
		const isStandardPlan = this.itemStore.SubscriptionStore.subscriptionPlan === "standard";
		
		return (
			<Modal
				centered
				key="LimitReachedContainerModal"
				size='mini'
				className='limitReachedPopup'
				open={this.itemStore.limitReachedError}
				closeOnDimmerClick={false} >
					<Header>
						<Icon name='warning sign' color="yellow"/>
						<Header.Content>{ value('lb_create_limitexceeded_header', _capitalize(this.STORE_TYPE.toLowerCase()))}</Header.Content>
					</Header>
					<Modal.Content>
						<Image wrapped src={ph_limitReachedImageURL} />
						<div style={{marginTop: "1rem", color: "rgb(154, 153, 153)"}}>
							<span>{value('msg_create_limitexceeded_header', this.STORE_TYPE.toLowerCase())}</span>
							<br/>
							<br/>
							<span>
								{
									isStandardPlan
									&&
									value('msg_create_limitexceeded_subheader1')
								}
								{
									!isStandardPlan
									&&
									value('msg_create_limitexceeded_subheader2')
								}
							</span>
						</div>
						<div style={{marginTop: "2rem", textAlign: "center", width: "100%"}}>
							<Button primary onClick={ () => this.itemStore.limitReachedError = false}>
								{value('bt_create_limitexceeded_okay')}
							</Button>
						</div>
					</Modal.Content>
			</Modal>
		);
	}

	/* Item Action Container */
	
	/*
	 * Building Action View for -
	 * 	Common Action
	 * 	Special Action
	 * 	Bulk Action
	*/
	getAllActionView(){
		const commonActionList = this.CommonActions;
		let commonViewContainer = {};
		const renderActionsStatus = _isUndefined(this.props.renderActions) ? true : this.props.renderActions;
		_forEach(commonActionList, (commonActionName) => {
			switch(commonActionName){

				case 'SEARCH' : commonViewContainer.searchBar = this.getSearchView();break;

				case 'SEARCH+' : commonViewContainer.searchBar = this.getSearchView(true);break;//Added for AddItemContainer/Popup based itemContainers

				case 'FILTER' : commonViewContainer.filterBar = this.getFilterView();break;

				case 'SORT' : commonViewContainer.sortBar = this.getSortView();break;
			}

		});
		const filterClassList = classNames({
			'filterNSelectionBar' : true,
			'emptyContainer' : this.isContainerEmpty()
		});
		const sortClassList = classNames({
			'sortBar' : true,
			'emptyContainer' : this.isContainerEmpty()
		});
		return (
			<Fragment>
				{commonViewContainer.searchBar}
				{
					<Segment basic className={filterClassList} key="filterBar">
						{commonViewContainer.filterBar}
						<Segment basic className="selectionBar">
							{renderActionsStatus ? this.getSpecialActionView()  : null}
							{renderActionsStatus ? this.getBulkActionView() 	: null}
						</Segment>
					</Segment>
				}
				{
					<Segment basic className={sortClassList} key="sortBar">
						{commonViewContainer.sortBar}
					</Segment>
				}
	        </Fragment>
		);
	}

	getSpecialActionContainer(){

		const specialActionList = this.SpecialActions;
		const specialActionContainer = _map(specialActionList, (specialActionName) => {
			switch(specialActionName){

				//case 'CREATE' : return this.getCreateContainer();

				case 'ADD_EXISTING' : return this.getAddExistingContainer();
			}

		});

		return specialActionContainer;
	}

	getItemActionContainer(){

		const itemActionList = this.ItemActions;
		const itemActionContainer = _map(itemActionList, (itemActionName) => {
			switch(itemActionName){

				case 'ADD_TO_COLLECTION' : return this.getAddToCollectionContainer();

				case 'ADD_TO_CATALOG' : return this.getAddToCatalogContainer();

				case 'DELETE' : return this.getDeleteContainer();

				case 'CLONE' : return this.getCloneContainer();
			}

		});

		return itemActionContainer;
	}

	getItemContainerView(){
		
		const itemsList = this.itemStore.ItemList;
		const subjectCounts = this.itemStore.SubjectCounts;
		const itemActionList = this.ItemActions;

		const activePage = this.getActivePage();		
		const totalPages = this.itemStore.totalPagesCount;
		const renderActionsStatus = _isUndefined(this.props.renderActions) ? true : this.props.renderActions;

		let viewMode = this.containerView;
		if(this.isContainerEmpty()){
			viewMode = "EmptyView";
		}

		let collageImageBuilder = null;
		if(this.ShowCollageView){
			collageImageBuilder = this.buildImageURLsForCollageView.bind(this);
		}

		const typedictonary = this.props.storeFactory.ModuleStore.getModuleTypes();
		const categorydictonary = this.props.storeFactory.ModuleStore.getModuleCategories(this.STORE_TYPE);

		switch(viewMode){
			case "CardView" : 	return (
									<ItemCardView
										containerCSS="cardView" 
										itemList={itemsList}
										typedictonary={typedictonary}
										categorydictonary={categorydictonary}
										itemActions={itemActionList} 	
										pagination_totalPages={totalPages}
										pagination_activePage={activePage}
										isLoading={this.itemStore.isLoading}
										selectOnClick={this.SelectOnClick}
										forPredicate={this.props.predicate}
										eventContext={this.predicateContext}
										searchStore={this.itemStore.searchStore}
										renderActions={renderActionsStatus}
										itemsPerRow={this.ItemsPerRow}
										subjectCounts={subjectCounts}
										forCollage={collageImageBuilder}
										showItemStock={this.ShowItemStock}
										currency={this.props.storeFactory.CurrencyStore.Currency} />
								);

			case "ListView" : 	return (
									<ItemListView
										containerCSS="listView" 
										itemList={itemsList}
										typedictonary={typedictonary}
										categorydictonary={categorydictonary}
										itemActions={itemActionList} 	
										pagination_totalPages={totalPages}
										pagination_activePage={activePage}
										isLoading={this.itemStore.isLoading}
										forPredicate={this.props.predicate}
										eventContext={this.predicateContext}
										searchStore={this.itemStore.searchStore}
										renderActions={renderActionsStatus}
										subjectCounts={subjectCounts}  
										forCollage={collageImageBuilder}
										currency={this.props.storeFactory.CurrencyStore.Currency}   />
								);
			
			case "TableView" : 	return (
									<ItemTableView
										containerCSS="listView" 
										itemList={itemsList}
										typedictonary={typedictonary}
										categorydictonary={categorydictonary}
										itemActions={itemActionList} 	
										pagination_totalPages={totalPages}
										pagination_activePage={activePage}
										isLoading={this.itemStore.isLoading}
										forPredicate={this.props.predicate}
										eventContext={this.predicateContext}
										filterStore={this.itemStore.filterStore}
										renderActions={renderActionsStatus}
										subjectCounts={subjectCounts}  
										forCollage={collageImageBuilder}
										currency={this.props.storeFactory.CurrencyStore.Currency}   />
								);
			case "CustomerTableView" : 	return (
									<CustomerTableView
										containerCSS="listView" 
										itemList={itemsList}
										typedictonary={typedictonary}
										categorydictonary={categorydictonary}
										itemActions={itemActionList} 	
										pagination_totalPages={totalPages}
										pagination_activePage={activePage}
										isLoading={this.itemStore.isLoading}
										forPredicate={this.props.predicate}
										eventContext={this.predicateContext}
										filterStore={this.itemStore.filterStore}
										renderActions={renderActionsStatus}
										subjectCounts={subjectCounts}  
										forCollage={collageImageBuilder}
										currency={this.props.storeFactory.CurrencyStore.Currency}   />
								);					

			case "EmptyView" : return this.renderEmptyView(this.containerContextName);

			default 		: 	return (
									<ItemListView
										containerCSS="listView" 
										itemList={itemsList}
										typedictonary={typedictonary}
										categorydictonary={categorydictonary}
										itemActions={itemActionList} 	
										pagination_totalPages={totalPages}
										pagination_activePage={activePage}
										isLoading={this.itemStore.isLoading}
										forPredicate={this.props.predicate}
										eventContext={this.predicateContext}
										searchStore={this.itemStore.searchStore}
										renderActions={renderActionsStatus}
										subjectCounts={subjectCounts}  
										forCollage={collageImageBuilder}
										currency={this.props.storeFactory.CurrencyStore.Currency}   />
								);
		}		
	}

	renderEmptyView(context){
		return (
			<div className="emptyViewContainer">
				<Segment placeholder style={{height: "100%"}}>
				    <Header icon size="small">
						<Icon circular inverted className="infoIcon" name='info' />
				      	<span style={{fontWeight: "500"}}>{value('lb_emptycontainermsg', context.toLowerCase())}</span>
				    </Header>	    
				</Segment>	
			</div>
		);
	}	

	renderView(){
		console.log("R. ItemContainer ...........");
		const renderActionsStatus = _isUndefined(this.props.renderActions) ? true : this.props.renderActions;
		const classList = classNames('itemContainer', this.props.containerStyle, this.STORE_TYPE);
		return (<Container fluid className={classList}>
					{ this.getAllActionView()}
					{ renderActionsStatus ? this.getSpecialActionContainer() 	: null}
					{ renderActionsStatus ? this.getItemActionContainer() 		: null}
					{ this.getItemContainerView()}
				</Container>);
	}
}