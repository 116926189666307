import React, { Component } from 'react';
import { Dropdown, Icon, Label } from 'semantic-ui-react';
import _flatten from 'lodash/flatten';
import _compact from 'lodash/compact';
import _map from 'lodash/map';
import _split from 'lodash/split';
import _find from 'lodash/find';
import { observer } from 'mobx-react';
import { emitter, EVENTS } from '../../../0-common/utils/EventEmitter';


@observer
export default class SortView extends Component {


    get SortOptions(){
        const _sortOptions = this.props.filterStore.SortOptionList;
        const sortOptionList = _compact(_flatten(_map(_sortOptions, sortOption => {
            const validSortOption = ( this.props.excludeSortOptions.indexOf(sortOption.uid) === -1 );
            if(validSortOption){
                return [
                    {
                        key: `${sortOption.uid}#asc`,
                        text: sortOption.Name,
                        value: `${sortOption.uid}#asc`,
                        content: this.getSortOptionContent(sortOption, "asc"),
                    },
                    {
                        key: `${sortOption.uid}#desc`,
                        text: sortOption.Name,
                        value: `${sortOption.uid}#desc`,
                        content: this.getSortOptionContent(sortOption, "desc"),
                    },
                ];
            }
        })));

        if(this.props.customSortOptions && this.props.customSortOptions.length > 0){
            const customSortOptionList = _map(this.props.customSortOptions, (customSortOption) => {
                return {
                    key: customSortOption.uid,
                    text: customSortOption.Name,
                    value: customSortOption.value,
                    content: this.getSortOptionContent(customSortOption, customSortOption.mode),
                }
            });
            sortOptionList.unshift(...customSortOptionList);
        }

        return sortOptionList;
    }

    applySort = (e, data) => {
        let selectedSortOption = null;
        if(data.value && (data.value.indexOf('#') !== -1)){
            const sortOptionInfo = _split(data.value, '#');
            selectedSortOption = {
                value : sortOptionInfo && sortOptionInfo[0],
                sortMode : sortOptionInfo && sortOptionInfo[1],
            }
        }
		emitter.emit(EVENTS.SORT.APPLY + this.props.eventContext, selectedSortOption);
		e.stopPropagation();
	}

    getSortOptionContent(sortOption, sortMode){
        switch (sortOption.uid) {
            case "Name":
            return(
                <div style={{display: "inline-flex", alignItems: "center", width: "100%", justifyContent: "space-between"}}>
                    <div>
                        <Icon name={sortMode === "desc" ? 'sort alphabet descending' : 'sort alphabet ascending'} />
                        <span>{sortOption.Name}</span>
                    </div>
                    <Label basic style={{marginLeft: "1rem", color: "var(--primary-color)", borderColor: "var(--primary-color)"}}>{sortMode === "desc" ? "Descending" : "Ascending"}</Label>
                </div>
            );

            case "DiscountedPrice":
            case "OrderPayableAmount" :
            return(
                <div style={{display: "inline-flex", alignItems: "center", width: "100%", justifyContent: "space-between"}}>
                    <div>
                        <Icon name={sortMode === "desc" ? 'sort numeric descending' : 'sort numeric ascending'} />
                        <span>{sortOption.Name}</span>
                    </div>
                    <Label basic style={{marginLeft: "1rem", color: "var(--primary-color)", borderColor: "var(--primary-color)"}}>{sortMode === "desc" ? "High to Low" : "Low to High"}</Label>
                </div>
            );
        
            default:
                return(
                    <div style={{display: "inline-flex", alignItems: "center", width: "100%", justifyContent: "space-between"}}>
                        <div>
                            <Icon name={sortMode === "desc" ? 'sort content descending' : 'sort content ascending'} />
                            <span>{sortOption.Name}</span>
                        </div>
                        <Label basic style={{marginLeft: "1rem", color: "var(--primary-color)", borderColor: "var(--primary-color)"}}>{sortMode === "desc" ? "Latest" : "Oldest"}</Label>
                    </div>
                );
        }
    }

    getActiveSortOptionIcon(activeSortId, activeSortMode){
        switch (activeSortId) {
            case "Name":
                return (activeSortMode === "desc" ? 'sort alphabet descending' : 'sort alphabet ascending');

            case "DiscountedPrice":
            case "OrderPayableAmount" :
                return (activeSortMode === "desc" ? 'sort numeric descending' : 'sort numeric ascending');

            default:
                return (activeSortMode === "desc" ? 'sort content descending' : 'sort content ascending');
        } 
    }

    render() {
        const activeSortOption = this.props.filterStore.ActiveSortOption;
        const activeSortId = activeSortOption && activeSortOption.value;
        const activeSortMode = activeSortOption && activeSortOption.sortMode;
        const activeSortValue = activeSortId && activeSortMode && `${activeSortOption.value}#${activeSortOption.sortMode}`;

        const availableSortOptions = this.SortOptions;
        const activeSort = _find(availableSortOptions, ["value", activeSortValue]);
        const dropdownText = `Sort by ${activeSort ? ` : ${activeSort.text}` : ''}`
        return (
            <div className="sortView">
                <Icon name={this.getActiveSortOptionIcon(activeSortId, activeSortMode)} />
                <div>
                    <Dropdown
                    text={dropdownText}
                    pointing="top right"
                    options={availableSortOptions}
                    defaultValue={activeSortValue}
                    onChange={this.applySort} />
                </div>
            </div>
        );
    }
}