import classNames from 'classnames';
import _find from 'lodash/find';
import _indexOf from 'lodash/indexOf';
import _isNil from 'lodash/isNil';
import _isNull from 'lodash/isNull';
import _round from 'lodash/round';
import _times from 'lodash/times';
import _without from 'lodash/without';
import { observer } from 'mobx-react';
import React from "react";
import { CheckCircle, ExternalLink, X } from 'react-feather';
import { Card, Dropdown, Header, Icon, Image, Label, Pagination, Placeholder, Popup, Segment } from 'semantic-ui-react';
import { value } from '../../0-common/store/LanguageStore';
import { ph_emptyImageURL } from "../../0-common/utils/DefaultImage";
import { emitter, EVENTS } from '../../0-common/utils/EventEmitter';
import parse from 'html-react-parser';
import 'react-quill/dist/quill.snow.css';

function executeItemOpenAction(itemId, itemType, itemCategory, eventContext){
	emitter.emit(EVENTS.ITEM.OPEN+eventContext, EVENTS.ITEM.OPEN, itemId, itemType, itemCategory);
}

function executeAddToCollectionAction(item){
	item.selected = true;
	emitter.emit(EVENTS.ITEM.ADDTOCOLLECTION.OPEN, EVENTS.ITEM.ADDTOCOLLECTION.OPEN);
}

function executeAddToCatalogAction(item){
	item.selected = true;
	emitter.emit(EVENTS.ITEM.ADDTOCATALOG.OPEN, EVENTS.ITEM.ADDTOCATALOG.OPEN);
}

function executeItemDeleteAction(itemId, itemType, eventContext){
	emitter.emit(EVENTS.ITEM.DELETE+eventContext, EVENTS.ITEM.DELETE, itemId, itemType);
}

function executeItemRemoveAction(itemId, eventContext, predicate){
	emitter.emit(EVENTS.PREDICATE.REMOVEITEM.START+eventContext, predicate, [itemId]);
}

function executeItemSelectAction(actionName, itemId, event){
	emitter.emit(actionName, actionName, itemId);
	event.stopPropagation();
}

function executePageAction(eventContext, e, {activePage}){
	emitter.emit(EVENTS.PAGE.PAGINATE+eventContext, activePage);
}

function stopPropagation(event){
	event.stopPropagation();
}

function executeOpenExternalLink(typeName, itemCategory, itemId, e){
	window.open(window.location.protocol+"//"+window.location.hostname+(window.location.port ? ':' + window.location.port : '') + "/app/" + typeName.toUpperCase() + "/" + typeName.toUpperCase() + "/" + itemCategory + "/" + itemId);
	stopPropagation(e);
}

function executeItemCloneAction(itemId, eventContext){
	emitter.emit(EVENTS.ITEM.CLONE+eventContext, [itemId]);
}


function buildCardView(props){

	const{ mode = "", itemList, itemsPerRow, isLoading, ...otherProps} = props;
	const items = Array.from(itemList);
	let cards = [];

	if(isLoading) {
		cards = buildLoadingCard(itemsPerRow);		
	} else {
		cards = items.map( item => {
			if(item.DATA)
				return buildCard(mode, item, item.selected, otherProps);		
	  	});
	}
	return(
		<Card.Group stackable doubling itemsPerRow={itemsPerRow}>
			{cards}			
		</Card.Group>	
	);	
}

function buildCard(mode, item, isSelected, props){
	const selectOnClick = props.selectOnClick;
	return (
			<Card
				raised
				key={item.DATA.uid}
				className={isSelected ? "selectedCard" : ""}
				onClick={ selectOnClick ? executeItemSelectAction.bind(null, EVENTS.ITEM.SELECT + props.eventContext, item.DATA.uid) : executeItemOpenAction.bind(null, item.DATA.uid, item.DATA.Type, item.DATA.Category, props.eventContext)} >
					{buildItemImageView(mode, item, isSelected, props)}
				    {buildItemContentView(mode, item, isSelected, props)}
				    
			</Card>
	);
}

function buildLoadingCard(itemsPerRow){
	const count = itemsPerRow ? itemsPerRow : 3;
	return _times(count,(counter) => {
		return (<Card key={counter}>              
			        <Placeholder>
			          <Placeholder.Image square />
			        </Placeholder>
			      	<Card.Content>
			          	<Placeholder>
				            <Placeholder.Header>
				              <Placeholder.Line length='very short' />
				              <Placeholder.Line length='medium' />
				            </Placeholder.Header>
				            <Placeholder.Paragraph>
				              <Placeholder.Line length='short' />
				            </Placeholder.Paragraph>
			          	</Placeholder>
			      	</Card.Content>
			     	<Card.Content extra>
				        <Placeholder>
				        	<Placeholder.Line length='very long' />
				        </Placeholder>
			      	</Card.Content>
		    	</Card>);
	});	
}

function buildItemImageView(mode, item, isSelected, props){
	let imageUrl = null;
	const collageImageUrl = props.forCollage && props.forCollage(item);
	if(collageImageUrl)
		imageUrl = prepareCollageImageUrl(collageImageUrl);
	else
		imageUrl = prepareImageUrl(item);
	//const selectedLabel = { corner: 'left', icon: 'check circle', color: 'blue' }
	
	const imageRatio = getImageSizeRatio(item);
	let imageCss = {};
	if(collageImageUrl || _isNull(imageRatio)){
		imageCss = classNames({
			imgCover : true,
			imgContain : false
		});
	} else {
		if( _round(imageRatio,2) === 1.33){
			imageCss = classNames({
				imgCover : true,
				imgContain : false
			});
		} else {
			imageCss = classNames({
				imgCover : false,
				imgContain : true
			});
		}
	}
	
	return (
		<div className="profile">
			<Image
				className={imageCss} 
				src={imageUrl}
				wrapped
				label={isSelected ? {corner: 'left', size:'medium', onClick:stopPropagation } : null} 
				onError={i => i.target.style.display='none'} />
			<div className="cardToolBoxCover">
				{buildMenuOptionsView(mode, item, isSelected, props)}
			</div>
		</div>
		
	);
	//{isSelected ? (<Label color='blue' corner='left' icon='check circle' />) : null}
	//return isSelected ? (<Image src={imageUrl} label={selectedLabel}/>) : (<Image src={imageUrl}/>);
}

function getImageSizeRatio(item){
	const imgRefId = item.DATA.ProfileImage;
	if(imgRefId) {
		const imgRef = item.REFERENCE_IMAGES[imgRefId];
		if(imgRef){
			if(imgRef.width > imgRef.height){
				return imgRef.width / imgRef.height;
			} else {
				return imgRef.height / imgRef.width;
			}
		} else {
			return null;
		}
	} else {
		return null;
	}
}

function prepareImageUrl(item){
	let imageUrl = ph_emptyImageURL;
	const imgRefId = item.DATA.ProfileImage;
	if(imgRefId) {
		const imgRef = item.REFERENCE_IMAGES[imgRefId];
		if(imgRef)
			imageUrl = "https://ucarecdn.com/" + imgRef.SourceID + "/"; 
	}
	return imageUrl;
}

function prepareCollageImageUrl(imageURLs){
	let collageURL = ph_emptyImageURL;
	if(imageURLs.length === 1){
		const singleImageURL = imageURLs[0];
		collageURL = "https://ucarecdn.com/" + singleImageURL + "/" + "-/overlay/" + singleImageURL + "/50px50p/center/";
		collageURL = collageURL + "-/blur/100/";
	} else if(imageURLs.length === 2){
		collageURL = "https://ucarecdn.com/" + imageURLs[0] + "/" + "-/overlay/" + imageURLs[0] + "/50px50p/0p,0p/";
		collageURL = collageURL + "-/overlay/" + imageURLs[1] + "/50px50p/100p,100p/";
		collageURL = collageURL + "-/blur/100/";
	} else if(imageURLs.length === 3){
		collageURL = "https://ucarecdn.com/" + imageURLs[0] + "/" + "-/overlay/" + imageURLs[0] + "/50px50p/0p,0p/";
		collageURL = collageURL + "-/overlay/" + imageURLs[1] + "/50px50p/100p,0p/";
		collageURL = collageURL + "-/overlay/" + imageURLs[2] + "/50px50p/50p,100p/";
		collageURL = collageURL + "-/blur/100/";
	}else if(imageURLs.length > 3){
		collageURL = "https://ucarecdn.com/" + imageURLs[0] + "/" + "-/overlay/" + imageURLs[0] + "/50px50p/0p,0p/";
		collageURL = collageURL + "-/overlay/" + imageURLs[1] + "/50px50p/100p,0p/";
		collageURL = collageURL + "-/overlay/" + imageURLs[2] + "/50px50p/0p,100p/";
		collageURL = collageURL + "-/overlay/" + imageURLs[3] + "/50px50p/100p,100p/";
		collageURL = collageURL + "-/blur/100/";
	}
	
	return collageURL;
}

function buildItemContentView(mode, item, isSelected, props){
	const imageUrl = prepareImageUrl(item);
	const itemCategory = item.DATA.Category;
	const category = _find(props.categorydictonary, ['key', itemCategory]);
	const categoryName = category ? category.text : "$$$";
	
	const ItemType = item.DATA.Type;
	const type = _find(props.typedictonary, ['key', ItemType]);
	const typeName = type ? type.text : "$$$";
	
	let itemActionList = props.itemActions;
	let isOpenExternal = false;
	if(itemActionList && itemActionList.length > 0){
		isOpenExternal = _indexOf(itemActionList, "OPEN_EXTERNAL_LABEL") > -1;
	}

	let showPrice = false;
	let discountPercentage = null;
	const retailPrice = item.DATA.RetailPrice;
	const discountedPrice = item.DATA.DiscountedPrice;

	if( discountedPrice || retailPrice){
		discountPercentage = discountedPrice && retailPrice && Math.round(((retailPrice - discountedPrice) / retailPrice) * 100);
		showPrice = true;
	}

	const inStock = item.DATA.InStock;
	const showItemStockIcon = props.showItemStock;

	return (<Card.Content>
				<Card.Meta style={{display: "flex", justifyContent: "space-between"}}>
					<Label basic className="itemCategory">
						{categoryName}
					</Label>
					{
						showItemStockIcon
						&&
						ItemType === "PRODUCTS"
						&&
						inStock
						&&
						<Label basic content="In Stock"  color="green" className="itemStock"/>
					}
					{
						showItemStockIcon
						&&
						ItemType === "PRODUCTS"
						&&
						!inStock
						&&
						<Label basic content="Out Of Stock"  color="red" className="itemStock"/>
					}
				</Card.Meta>
				{
					isOpenExternal
					&&
					<Popup
						key="open"
						position='top left'
						size='tiny'
						trigger={
							<div className="itemLabel"
								onClick={executeOpenExternalLink.bind(null, typeName, item.DATA.Category, item.DATA.uid)} > 
								{item.DATA.Name} 
							</div>
						}>
						
						<Popup.Content>
							{value('pop_card_action_name_open_in_tab')}
						</Popup.Content>
					</Popup>
				}
				{
					!isOpenExternal
					&&
					<div className="itemLabel"> 
						{item.DATA.Name} 
					</div>
				}
				{
					showPrice
					&&
					<div className="productPrice">
						{
							discountedPrice
							&&
							<span className="product-discountedPrice">{props.currency.format(discountedPrice)}</span>
						}
						{
							retailPrice
							&&
							<span className="product-retailPrice">{props.currency.format(retailPrice)} </span>
						}
						{
							(discountPercentage > 0)
							&&
							<span className="product-discountPercentage">( {discountPercentage}% OFF )</span>
						}
					</div>
				}
				{
					!showPrice
					&&
					<div className="itemDesc">
						{item.DATA.Description}
					</div> 
				}
			  	
		    </Card.Content>);
}

function buildMenuOptionsView(mode, item, isSelected, props){
	let itemActionList = props.itemActions;
	if(itemActionList && itemActionList.length > 0){
		const itemActionListForOptions = _without(itemActionList, 'SELECT', 'DELETE', 'OPEN_EXTERNAL')
		return (
			<Segment basic className="cardToolBox" onClick={stopPropagation}>		
				{buildItemActionView(item, itemActionList, isSelected, props)}
				{buildOptionView(item, itemActionListForOptions, props.forPredicate, props.eventContext, props.subjectCounts)}		
			</Segment> 
		);
	} else {
		return null;
	} 

}

function buildItemActionView(item, itemActions, isSelected, props){
	const itemType = item.DATA.Type;
	const type = _find(props.typedictonary, ['key', itemType]);
	const typeName = type ? type.value : "$$$";

	return itemActions.map((actionName) => {		
		switch(actionName) {

			case 'SELECT'				: 	return buildSelectionView(item, isSelected, props.forPredicate, props.eventContext);

			case 'DELETE' 				: 	return (
												<Popup
													key="delete"
													content={value('pop_card_action_delete')}
													position='bottom right'
													size='tiny'
													trigger={
														<Icon
															className="itemDeleteAction"
															key={actionName}
															inverted
															size='large'
															onClick={executeItemDeleteAction.bind(null, item.DATA.uid, item.DATA.Type, props.eventContext)} >
																<X />
														</Icon>
													} />
											);
			case 'OPEN_EXTERNAL' 		: 	return (
												<Popup
													key="openExternal"
													content={value('pop_card_action_open_external')}
													position='bottom right'
													size='tiny'
													trigger={
														<Icon
															style={{margin: "0 0.5rem 0.5rem 0rem"}} 
															inverted
															size='large'  
															onClick={executeOpenExternalLink.bind(null, typeName, item.DATA.Category, item.DATA.uid)} >
																<ExternalLink size="22"/>
														</Icon>
													} />
											);
			case 'REMOVE' 				: 	return (
												<Popup
													key="remove"
													content={value('pop_card_action_remove')}
													position='bottom right'
													size='tiny'
													trigger={
														<Icon
															className="itemDeleteAction"
															key={actionName}
															inverted
															size='large'
															onClick={executeItemRemoveAction.bind(null, item.DATA.uid, props.eventContext, props.forPredicate)} >
																<X />
														</Icon>
													} />
											);
		}
	});
}

function buildSelectionView(item, isSelected, predicate, eventContext){

	if(isSelected) {
		return (
			<Popup
				key="DESELECT"
				content={value('pop_card_action_deselect')}
				position='bottom right'
				size='tiny'
				trigger={
					<Icon
						key="SELECT" 
						className="itemSelectAction itemSelected" 
						size='large'
						onClick={executeItemSelectAction.bind(null, EVENTS.ITEM.SELECT + eventContext, item.DATA.uid)} >
							<CheckCircle />
					</Icon>
				}	/>		
			
		);
	} else {
		return (
			<Popup
				key="SELECT"
				content={value('pop_card_action_select')}
				position='bottom right'
				size='tiny'
				trigger={
					<Icon 
						key="DESELECT"
						className="itemSelectAction itemDeselected" 
						size='large'  
						onClick={executeItemSelectAction.bind(null, EVENTS.ITEM.SELECT + eventContext, item.DATA.uid)} >
							<CheckCircle />
					</Icon>
				} />
		);
	}
}

function buildOptionView(item, itemActions, predicate, eventContext, subjectCounts){
	
	if(!itemActions) {
		return null;
	}
	else if(itemActions && itemActions.length === 0){
		return null;
	} 
	const {uid:itemId, Type:itemType, Name:itemName} = item.DATA;
	const predicateName = item.PREDICATE ? item.PREDICATE : predicate;

	const collectionCount = subjectCounts.COLLECTIONS || 0;
	const catalogCount = subjectCounts.CATALOGS || 0;
	
	return(
		<Dropdown icon="ellipsis vertical" className="cardMenuOptions" pointing="top right" >
		    <Dropdown.Menu>
		        {
					itemActions.map((actionName) => {		
						switch(actionName) {

							case 'ADD_TO_COLLECTION' 	: 	return (
																<Dropdown.Item
																	key={actionName} 
										        					icon="folder open"
										        					text={value('bt_menu_addtocollection')}
										        					disabled={collectionCount === 0}
										        					onClick={executeAddToCollectionAction.bind(null, item)} />
										        			);
							case 'ADD_TO_CATALOG' 		: 	return (
																<Dropdown.Item
																	key={actionName}  
												        			icon="map"
												        			text={value('bt_menu_addtocatalog')}
												        			disabled={catalogCount === 0}
												        			onClick={executeAddToCatalogAction.bind(null, item)} />
															);
							case 'REMOVE'				: 	return (
																<Dropdown.Item
																	key={actionName}  
												        			icon="remove circle"
												        			text={value('bt_menu_remove')}
												        			onClick={executeItemRemoveAction.bind(null, itemId, eventContext, predicateName)} />	
															);
							case 'CLONE'				: 	return (
																<Dropdown.Item
																	key={actionName}  
												        			icon="copy"
															        text={value('bt_menu_clone')}
												        			onClick={executeItemCloneAction.bind(null, itemId, eventContext)} />	
															);
						}
					})
		        }      
		    </Dropdown.Menu>
		</Dropdown>
	);
}

/*function buildSelectionLabel(mode, itemActions, item, isSelected, predicate, eventContext){

	if(mode === 'card'){
		return null;
	} else if( mode === 'selector'){
		if(isSelected) {
			return (<Popup
						key="DESELECT"
				        trigger={<Icon className="itemSelectAction itemSelected" link name='check circle' size='large' color='blue'  onClick={executeItemSelectAction.bind(null, EVENTS.ITEM.SELECTOR + eventContext, item.DATA.uid)} />}
				        content={value('tt_deselect')} 
				        position='bottom center'
				        size='tiny'
				        inverted />);
		} else {
			return (<Popup
						key="SELECT"
				        trigger={<Icon className="itemSelectAction itemDeselected" link name='check circle outline' size='large' color='grey'  onClick={executeItemSelectAction.bind(null, EVENTS.ITEM.SELECTOR + eventContext, item.DATA.uid)} />}
				        content={value('tt_select')}
				        position='bottom center'
				        size='tiny'
				        inverted />);
		}
	} else {
		return itemActions.map((actionName) => {		
			switch(actionName) {

				case 'SELECT' 	: {
					if(isSelected) {
						return (<Popup
									key="DESELECT"
							        trigger={<Icon className="itemSelectAction itemSelected" link name='check circle' size='large' color='blue'  onClick={executeItemSelectAction.bind(null, EVENTS.ITEM.SELECT + eventContext, item.DATA.uid)} />}
							        content={value('tt_deselect')}
							        position='bottom center'
							        size='tiny'
							        inverted />);
					} else {
						return (<Popup
									key="SELECT"
							        trigger={<Icon className="itemSelectAction itemDeselected" link name='check circle outline' size='large' color='grey'  onClick={executeItemSelectAction.bind(null, EVENTS.ITEM.SELECT + eventContext, item.DATA.uid)} />}
							        content={value('tt_select')}
							        position='bottom center'
							        size='tiny'
							        inverted />);
					}
				}
			}
		});		
	}
}*/

/*function buildActionList(mode, itemActions, isSelected, item, predicate, eventContext){
	
	if(!itemActions) 
		return [];

	const {uid, Type, Category} = item.DATA;
	if(mode === 'card'){
		return itemActions.map((actionName) => {		
			switch(actionName) {
				case 'REMOVE' : return (<Popup
											key="REMOVE"
								        	trigger={<Icon className="itemRemoveAction" link name='remove circle' size='large' onClick={executeItemRemoveAction.bind(null, eventContext, predicate, uid)} />}
								        	content={value('tt_remove')}
								        	position='bottom center'
								        	size='tiny'
								        	inverted />);
			}
		});
	}
	else{
		return itemActions.map((actionName) => {		
			switch(actionName) {
				case 'OPEN' 	: return (<Popup
										   key="OPEN"
								           trigger={<Icon  link name='envelope open' size='large' color='teal' onClick={executeItemOpenAction.bind(null, EVENTS.ITEM.OPEN,uid, Type, Category)} />}
								           content={value('tt_open')} 
								           position='bottom center'
								           size='tiny'
								           inverted />);
				case 'REMOVE' 	: return (<Popup
											key="REMOVE"
								        	trigger={<Icon className="itemRemoveAction" link name='remove circle' size='large' onClick={executeItemRemoveAction.bind(null, eventContext, predicate, uid)} />}
								        	content={value('tt_remove')}
								        	position='bottom center'
								        	size='tiny'
								        	inverted />);
				case 'DELETE' 	: return (<Popup
											key="DELETE"
								        	trigger={<Icon className="itemDeleteAction" link name='trash alternate outline' size='large' color='red'  onClick={executeItemDeleteAction.bind(null, EVENTS.ITEM.DELETE, uid, Type)} />}
								        	content={value('tt_delete')}
								        	position='bottom center'
								        	size='tiny'
								        	inverted />);
			}
		});	
	}
	
}*/

function buildPaginationView(props){

	const {pagination_activePage, pagination_totalPages, isLoading} = props;	
	if( !_isNil(pagination_activePage) && !_isNil(pagination_totalPages) && !isLoading) {
		return (
			<Segment basic className="paginationView">
				<Pagination 
					pointing 
					secondary 
					defaultActivePage={pagination_activePage} 
					totalPages={pagination_totalPages}
					firstItem={null}
    				lastItem={null}
    				onPageChange={executePageAction.bind(this, props.eventContext)} />
			</Segment> 
		);
	}
}

function buildMainView(props){

	const { containerCSS, ...otherProps } = props;
	const cardView = buildCardView(otherProps);

	return (
		<div className={containerCSS} >
			
			{cardView}

		</div>
	);
}

function CardView(props) {
	console.log("R. CardView");
	//For EMPTY Search Result
	let noResultsFound = false;
	if(props.searchStore.finalSearchQuery !== "" && props.itemList && props.itemList.length === 0){
		noResultsFound= true;
	}	
	
  	if(noResultsFound){
  		return (
			<div className="itemContentContainer">	
				<Segment placeholder className="noResultsFound">
				    <Header icon>
				      <Icon name='search' />
				      {value('lb_noResultsFound')} '{props.searchStore.finalSearchQuery}'
				    </Header>
				</Segment>
			</div>
  		);

  	} else {

  		const cardView = buildMainView(props);
  		const pagination = buildPaginationView(props);
  		return (
			<div className="itemContentContainer">		
				{cardView}
				{pagination}
			</div>
		);
  	}
	
};

export default observer(CardView);