import { observer } from 'mobx-react';
import React from 'react';
import { Button, Header, Icon, Modal, Segment } from 'semantic-ui-react';
import { value } from '../../../0-common/store/LanguageStore';



const ConfirmDelete = ({deleteStatus, store, context,  onConfirm, onCancel}) => {
	let contextType = context;
	const deleteHeader = value('lb_delete_header', contextType);
	const deleteButtonLabel = value('bt_delete_ok', contextType.toLowerCase());
	return (<Modal
				key="confirmDelete"
				centered
      			open={deleteStatus.confirmDelete} 
      			closeOnDimmerClick={false}
      			size="tiny" >
					<Header as='h3'>
						<Icon name='warning sign' style={{color: "var(--primary-color)"}} />
						<Header.Content>{deleteHeader}</Header.Content>
					</Header>
	      			<Modal.Content>
					  
						<Segment basic textAlign="center">
							<Header as='h4' icon>
								<Icon name='trash' style={{color: "var(--primary-color)"}} circular/>
								<Header.Content>
									{value('lb_delete_body_header', contextType.toLowerCase())}
									<Header.Subheader>
										{value('lb_delete_body_subheader')}
									</Header.Subheader>
								</Header.Content>
							</Header>
						</Segment>

	      			</Modal.Content>
					<Modal.Actions>
						<Button
							primary 
							content={deleteButtonLabel} 
							onClick={onConfirm} />
						<Button
							content={value('bt_delete_cancel')}
							onClick={onCancel} />
					</Modal.Actions>
      		</Modal>);
}

export default observer(ConfirmDelete);