import _find from 'lodash/find';
import _times from 'lodash/times';
import { observer } from 'mobx-react';
import React from "react";
import { ChevronDown, ChevronUp, ExternalLink, X } from 'react-feather';
import { Header, Icon, Item, Label, Pagination, Placeholder, Popup, Ref, Segment } from 'semantic-ui-react';
import { value } from '../../0-common/store/LanguageStore';
import { ph_emptyImageURL } from "../../0-common/utils/DefaultImage";
import { emitter, EVENTS } from '../../0-common/utils/EventEmitter';
import useScroll from '../../0-common/utils/useScroll';
import parse from 'html-react-parser';
import 'react-quill/dist/quill.snow.css';

function executeItemOpenAction(actionName, sourceAction, itemId, type, category){
	emitter.emit(actionName, actionName, sourceAction, itemId, type, category);
}

function executeAddToCollectionAction(item, eventContext){
	item.selected = true;
	emitter.emit(EVENTS.ITEM.ADDTOCOLLECTION.OPEN, EVENTS.ITEM.ADDTOCOLLECTION.OPEN);
}

function executeAddToCatalogAction(item, eventContext){
	item.selected = true;
	emitter.emit(EVENTS.ITEM.ADDTOCATALOG.OPEN, EVENTS.ITEM.ADDTOCATALOG.OPEN);
}

function executeItemDeleteAction(actionName, itemId, type, event){
	emitter.emit(actionName, actionName, itemId, type);
	event.stopPropagation();
}

function executeItemRemoveAction(eventContext, predicate, uid){
	emitter.emit(EVENTS.PREDICATE.REMOVEITEM.START+eventContext, predicate, [uid]);
	event.stopPropagation();
}

function executeItemSelectAction(actionName, itemId, event){
	emitter.emit(actionName, actionName, itemId);
	event.stopPropagation();
}

function executePageAction(eventContext, e, {activePage}){
	emitter.emit(EVENTS.PAGE.PAGINATE+eventContext, activePage);
}

function executeCreateAction(eventName){
	emitter.emit(eventName, eventName);
}

function executeMoveAction(eventContext, moveMode, itemIdToBeMoved){
	emitter.emit(EVENTS.PREDICATE.MOVEITEM+eventContext, moveMode, itemIdToBeMoved);
}

function stopPropagation(event){
	event.stopPropagation();
}

function executeOpenExternalLink(typeName, itemCategory, itemId, e){
	window.open(window.location.protocol+"//"+window.location.hostname+(window.location.port ? ':' + window.location.port : '') + "/app/" + typeName.toUpperCase() + "/" + typeName.toUpperCase() + "/" + itemCategory + "/" + itemId);
	stopPropagation(e);
}

function buildItemListView(props){

	const{ mode = "", itemList, itemsPerRow, isLoading, ...otherProps} = props;
	const itemArray = Array.from(itemList);
	let items = [];
	

	if(isLoading) {
		items = buildLoadingItems(itemsPerRow);
	} else {
		items = itemArray.map( item => {
			if(item.DATA)
				return buildItemList(mode, item, item.selected, otherProps);		
	  	});
	}
	return(
		<Item.Group link={otherProps.renderActions} divided>
			{items}	
		</Item.Group>
	);	
}

function buildItemList(mode, item, isSelected, props){
	const elScroll = useScroll(props.forPredicate);
	return (
			<Ref innerRef={elScroll.htmlElRef.bind(null, item.DATA.uid)} key={item.DATA.uid}>
				<Item
					key={item.DATA.uid}
					className={isSelected ? "selectedItem" : ""}
					onClick={props.renderActions ? executeItemSelectAction.bind(null, EVENTS.ITEM.SELECT + props.eventContext, item.DATA.uid) : null} >

			      		{buildItemImageView(mode, item, isSelected, props)}
						{buildItemContentView(mode, item, isSelected, props)}
						{buildItemActionView(item, props)}
			    </Item>
		    </Ref>
	);
}

function buildLoadingItems(items){
	const count = items ? items : 5;
	return _times(count,(counter) => {
		return (<Item key={counter}>              
			        <Placeholder>
			          <Placeholder.Image square />
			        </Placeholder>
			      	<Item.Content>
			          	<Placeholder>
				            <Placeholder.Header>
				              <Placeholder.Line length='very short' />
				              <Placeholder.Line length='medium' />
				            </Placeholder.Header>
				            <Placeholder.Paragraph>
				              <Placeholder.Line length='short' />
				            </Placeholder.Paragraph>
			          	</Placeholder>
			      	</Item.Content>
		    	</Item>);
	});	
}

function buildItemImageView(mode, item, isSelected, props){
	if(mode === "compact")
		return null;
	
	//const imageUrl = props.forCollage ? prepareCollageImageUrl(props.forCollage(item)) : prepareImageUrl(item);
	
	let imageUrl = null;
	const collageImageUrl = props.forCollage && props.forCollage(item);
	if(collageImageUrl)
		imageUrl = prepareCollageImageUrl(collageImageUrl);
	else
		imageUrl = prepareImageUrl(item);
	
	//const selectedLabel = { corner: 'left', icon: 'check circle', color: 'blue' }
	return (
		<Item.Image 
			size='tiny' 
			src={imageUrl}
			label={isSelected ? {corner: 'left', size:'mini', onClick:stopPropagation } : null} 
			onError={i => i.target.style.display='none'} />
	);
	//{isSelected ? (<Label color='blue' corner='left' icon='check circle' />) : null}
	//return isSelected ? (<Image src={imageUrl} label={selectedLabel}/>) : (<Image src={imageUrl}/>);
}

function prepareImageUrl(item){
	let imageUrl = ph_emptyImageURL;
	const imgRefId = item.DATA.ProfileImage;
	if(imgRefId) {
		const imgRef = item.REFERENCE_IMAGES[imgRefId];
		if(imgRef)
			//imageUrl = "https://ucarecdn.com/" + imgRef.SourceID + "/-/format/auto/-/quality/lightest/-/preview/250x100/"; 
			imageUrl = "https://ucarecdn.com/" + imgRef.SourceID + "/-/format/auto/-/quality/smart/"; 
	}
	return imageUrl;
}

function prepareCollageImageUrl(imageURLs){
	let collageURL = ph_emptyImageURL;
	if(imageURLs.length === 1){
		const singleImageURL = imageURLs[0];
		collageURL = "https://ucarecdn.com/" + singleImageURL + "/" + "-/overlay/" + singleImageURL + "/50px50p/center/";
		collageURL = collageURL + "-/blur/100/";
	} else if(imageURLs.length === 2){
		collageURL = "https://ucarecdn.com/" + imageURLs[0] + "/" + "-/overlay/" + imageURLs[0] + "/50px50p/0p,0p/";
		collageURL = collageURL + "-/overlay/" + imageURLs[1] + "/50px50p/100p,100p/";
		collageURL = collageURL + "-/blur/100/";
	} else if(imageURLs.length === 3){
		collageURL = "https://ucarecdn.com/" + imageURLs[0] + "/" + "-/overlay/" + imageURLs[0] + "/50px50p/0p,0p/";
		collageURL = collageURL + "-/overlay/" + imageURLs[1] + "/50px50p/100p,0p/";
		collageURL = collageURL + "-/overlay/" + imageURLs[2] + "/50px50p/50p,100p/";
		collageURL = collageURL + "-/blur/100/";
	}else if(imageURLs.length > 3){
		collageURL = "https://ucarecdn.com/" + imageURLs[0] + "/" + "-/overlay/" + imageURLs[0] + "/50px50p/0p,0p/";
		collageURL = collageURL + "-/overlay/" + imageURLs[1] + "/50px50p/100p,0p/";
		collageURL = collageURL + "-/overlay/" + imageURLs[2] + "/50px50p/0p,100p/";
		collageURL = collageURL + "-/overlay/" + imageURLs[3] + "/50px50p/100p,100p/";
		collageURL = collageURL + "-/blur/100/";
	}
	
	return collageURL;
}


function buildItemContentView(mode, item, isSelected, props){

	const itemCategory = item.DATA.Category;
	const category = _find(props.categorydictonary, ['key', itemCategory]);
	const categoryName = category ? category.text : "$$$";

	const itemType = item.DATA.Type;
	const type = _find(props.typedictonary, ['key', itemType]);
	const typeName = type ? type.value : "$$$";


	return (<Item.Content>
				<Popup
					key="open"
					position='top left'
					size='tiny'
					trigger={
						<Item.Header 
							as="a"
							style={{fontFamily: "var(--primary-font)", fontWeight: "500", fontSize:"1rem"}}
							onClick={executeOpenExternalLink.bind(null, typeName, item.DATA.Category, item.DATA.uid)} >
									{item.DATA.Name}
						</Item.Header>} >
					
					<Popup.Content>
						Open Product &nbsp; <ExternalLink size="14"/>
					</Popup.Content>
				</Popup>
		        <Item.Description className="ql-editor">{parse(item.DATA.Description_RT || item.DATA.Description || "")}</Item.Description>
		        <Item.Extra>
					<Label basic className="itemCategory">
				      {categoryName}
				    </Label>
		        </Item.Extra>
	      	</Item.Content>
	);
}

function buildItemActionView(item, props){
	if(props.renderActions){
		return (
			<Segment basic className="cardToolBox" onClick={stopPropagation} >
				{
					props.itemActions.map((actionName) => {		
						switch(actionName) {
							case 'REMOVE' 		: return (<Popup
															key="remove"
															content={value('lb_common_listview_action_remove')} 
															position='bottom right'
															size='tiny'
															trigger={<Icon size='large' className="productToolBarIcon" color="orange" onClick={executeItemRemoveAction.bind(null, props.eventContext, props.forPredicate, item.DATA.uid)} >
																		<X size="16" />
																	</Icon>} />);
												
							case 'MOVE UP' 		: return (<Popup 
															key="moveUp"
															content={value('lb_common_listview_action_moveup')} 
															position="bottom right" 
															size="tiny"
															trigger={<Icon size="large" className="productToolBarIcon" onClick={executeMoveAction.bind(this, props.eventContext, "UP", [item.DATA.uid])}>
																		<ChevronUp size="16"/>
																	</Icon>} />);
							
							case 'MOVE DOWN' 	: return (<Popup 
															key="moveDown"
															content={value('lb_common_listview_action_movedown')} 
															position="bottom right" 
															size="tiny"
															trigger={<Icon size="large" className="productToolBarIcon" onClick={executeMoveAction.bind(this, props.eventContext, "DOWN", [item.DATA.uid])}>
																		<ChevronDown size="16"/>
																	</Icon>} />);
						}
					})
				}		
			</Segment> 
		);
	}
}

function buildRemoveView(item, predicateName, eventContext){
	return (
		<Icon
			style={{fontSize: "1.3em"}} 
			key="remove"
			link
			size='large'
			name="times circle outline"  
			onClick={executeItemRemoveAction.bind(null, eventContext, predicateName, item.DATA.uid)} />
	);
}

/*function buildOptionView(item, itemActions, predicate, eventContext, subjectCounts){
	
	if(!itemActions) {
		return null;
	}
	else if(itemActions && itemActions.length === 0){
		return null;
	} 
	const {uid:itemId, Type:itemType, Name:itemName} = item.DATA;
	const predicateName = item.PREDICATE ? item.PREDICATE : predicate;

	const collectionCount = subjectCounts.COLLECTIONS;
	const catalogCount = subjectCounts.CATALOGS;

	return(
		<Dropdown icon="ellipsis vertical" className="cardMenuOptions" pointing="top right" >
		    <Dropdown.Menu>
		        <Dropdown.Header>{itemName}</Dropdown.Header>
		        <Dropdown.Divider />
		        {
					itemActions.map((actionName) => {		
						switch(actionName) {

							case 'ADD_TO_COLLECTION' 	: 	return (
																<Dropdown.Item
																	key={actionName} 
										        					icon="folder open"
										        					text={value('bt_menu_addtocollection')}
										        					disabled={collectionCount === 0}
										        					onClick={executeAddToCollectionAction.bind(null, item, eventContext)} />
										        			);
							case 'ADD_TO_CATALOG' 		: 	return (
																<Dropdown.Item
																	key={actionName}  
												        			icon="map"
												        			text={value('bt_menu_addtocatalog')}
												        			disabled={catalogCount === 0}
												        			onClick={executeAddToCatalogAction.bind(null, item, eventContext)} />
															);
							case 'DELETE' 				: 	return (
																<Dropdown.Item
																	key={actionName}  
												        			icon="trash alternate"
												        			text={value('bt_menu_delete')}
												        			onClick={executeItemDeleteAction.bind(null, itemId, itemType, eventContext)} /> 
															);
							case 'MOVE UP' 				: return (
																<Dropdown.Item
																	key={actionName}
																	icon='angle up'
																	text="Move Up"
																	onClick={executeMoveAction.bind(null, "UP", eventContext)} />
															);

							case 'MOVE DOWN' 			: return (
																<Dropdown.Item
																	icon='angle down'
																	key={actionName}
																	text="Move Down"
																	onClick={executeMoveAction.bind(null, "DOWN", eventContext)} />
														);

							case 'MOVE TOP' 			: return (
																<Dropdown.Item
																	icon='angle double up'
																	key={actionName}
																	text="Move Top"
																	onClick={executeMoveAction.bind(null, "TOP", eventContext)} />
														);

							case 'MOVE BOTTOM' 			: return (
																<Dropdown.Item
																	icon='angle double down'
																	key={actionName}
																	text="Move Bottom"
																	onClick={executeMoveAction.bind(null, "BOTTOM", eventContext)} />
														);
						}
					})
		        }      
		    </Dropdown.Menu>
		</Dropdown>
	);
}*/

/*function buildSelectionLabel(mode, itemActions, item, isSelected, predicate, eventContext){

	if(mode === 'card'){
		return null;
	} else if( mode === 'selector'){
		if(isSelected) {
			return (<Popup
						key="DESELECT"
				        trigger={<Icon className="itemSelectAction itemSelected" link name='check circle' size='large' color='blue'  onClick={executeItemSelectAction.bind(null, EVENTS.ITEM.SELECTOR + eventContext, item.DATA.uid)} />}
				        content={value('tt_deselect')} 
				        position='bottom center'
				        size='tiny'
				        inverted />);
		} else {
			return (<Popup
						key="SELECT"
				        trigger={<Icon className="itemSelectAction itemDeselected" link name='check circle outline' size='large' color='grey'  onClick={executeItemSelectAction.bind(null, EVENTS.ITEM.SELECTOR + eventContext, item.DATA.uid)} />}
				        content={value('tt_select')}
				        position='bottom center'
				        size='tiny'
				        inverted />);
		}
	} else {
		return itemActions.map((actionName) => {		
			switch(actionName) {

				case 'SELECT' 	: {
					if(isSelected) {
						return (<Popup
									key="DESELECT"
							        trigger={<Icon className="itemSelectAction itemSelected" link name='check circle' size='large' color='blue'  onClick={executeItemSelectAction.bind(null, EVENTS.ITEM.SELECT + eventContext, item.DATA.uid)} />}
							        content={value('tt_deselect')}
							        position='bottom center'
							        size='tiny'
							        inverted />);
					} else {
						return (<Popup
									key="SELECT"
							        trigger={<Icon className="itemSelectAction itemDeselected" link name='check circle outline' size='large' color='grey'  onClick={executeItemSelectAction.bind(null, EVENTS.ITEM.SELECT + eventContext, item.DATA.uid)} />}
							        content={value('tt_select')}
							        position='bottom center'
							        size='tiny'
							        inverted />);
					}
				}
			}
		});		
	}
}*/

/*function buildActionList(mode, itemActions, isSelected, item, predicate, eventContext){
	
	if(!itemActions) 
		return [];

	const {uid, Type, Category} = item.DATA;
	if(mode === 'card'){
		return itemActions.map((actionName) => {		
			switch(actionName) {
				case 'REMOVE' : return (<Popup
											key="REMOVE"
								        	trigger={<Icon className="itemRemoveAction" link name='remove circle' size='large' onClick={executeItemRemoveAction.bind(null, eventContext, predicate, uid)} />}
								        	content={value('tt_remove')}
								        	position='bottom center'
								        	size='tiny'
								        	inverted />);
			}
		});
	}
	else{
		return itemActions.map((actionName) => {		
			switch(actionName) {
				case 'OPEN' 	: return (<Popup
										   key="OPEN"
								           trigger={<Icon  link name='envelope open' size='large' color='teal' onClick={executeItemOpenAction.bind(null, EVENTS.ITEM.OPEN,uid, Type, Category)} />}
								           content={value('tt_open')} 
								           position='bottom center'
								           size='tiny'
								           inverted />);
				case 'REMOVE' 	: return (<Popup
											key="REMOVE"
								        	trigger={<Icon className="itemRemoveAction" link name='remove circle' size='large' onClick={executeItemRemoveAction.bind(null, eventContext, predicate, uid)} />}
								        	content={value('tt_remove')}
								        	position='bottom center'
								        	size='tiny'
								        	inverted />);
				case 'DELETE' 	: return (<Popup
											key="DELETE"
								        	trigger={<Icon className="itemDeleteAction" link name='trash alternate outline' size='large' color='red'  onClick={executeItemDeleteAction.bind(null, EVENTS.ITEM.DELETE, uid, Type)} />}
								        	content={value('tt_delete')}
								        	position='bottom center'
								        	size='tiny'
								        	inverted />);
			}
		});	
	}
	
}*/

function buildPaginationView(props){

	const {pagination_activePage, pagination_totalPages, isLoading} = props;	
	if(pagination_activePage && pagination_totalPages && !isLoading) {
		return (
			<Segment basic className="paginationView" style={{flex: "0 1"}}>
				<Pagination 
					pointing 
					secondary 
					defaultActivePage={pagination_activePage} 
					totalPages={pagination_totalPages}
					firstItem={null}
    				lastItem={null}
    				onPageChange={executePageAction.bind(this, props.eventContext)} 
				/>
			</Segment> 
		);
	}
}

function buildListView(props){
 
	const { containerCSS, ...otherProps } = props;
	const listView = buildItemListView(otherProps);

	return (
		<div className={containerCSS} style={{flex: "1 0", height: "100%", overflowY: "auto"}}>
			{listView}
		</div>
	);
}

function ListView(props) {

	//For EMPTY Search Result
	let noResultsFound = false;
	if(props.searchStore.finalSearchQuery !== "" && props.itemList && props.itemList.length === 0){
		noResultsFound= true;
	}	
	
  	if(noResultsFound){
  		return (
			<div className="listViewContainer">	
				<Segment placeholder className="noResultsFound">
				    <Header icon>
				      <Icon name='search' />
				      {value('lb_noResultsFound')} '{props.searchStore.finalSearchQuery}'
				    </Header>
				</Segment>
			</div>
  		);

  	} else {

  		const listView = buildListView(props);
  		const pagination = buildPaginationView(props);
  		return (
			<div className="listViewContainer" style={{display: "flex", flexDirection: "column"}}>

				{listView}

				{pagination}

			</div>
		);
  	}
	
};

export default observer(ListView);