import _find from 'lodash/find';
import _map from 'lodash/map';
import _times from 'lodash/times';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import {
	Container,
	Pagination,
	Placeholder,
	Segment,
	Table,
	Dropdown,
	Modal,
	Button,
} from 'semantic-ui-react';
import { emitter, EVENTS } from '../../0-common/utils/EventEmitter';

const showDeleteWarning = observable({
	value: false,
});
const selectedItem = observable({
	uid: '',
	type: '',
});

function executePageAction(eventContext, e, { activePage }) {
	emitter.emit(EVENTS.PAGE.PAGINATE + eventContext, activePage);
}
function executeItemOpenAction(itemId, itemCategory, eventContext) {
	emitter.emit(
		EVENTS.ITEM.OPEN + eventContext,
		EVENTS.ITEM.OPEN,
		itemId,
		'USER',
		itemCategory
	);
}

function deleteCustomer(id) {
	emitter.emit(EVENTS.ITEM.DELETE, EVENTS.ITEM.DELETE, id, 'USER');
}

function buildLoadingView() {
	const count = 6;
	return (
		<Table.Body>
			{_times(count, (counter) => {
				return (
					<Table.Row key={counter}>
						<Table.Cell>
							<Placeholder>
								<Placeholder.Line length='medium' />
								<Placeholder.Line length='medium' />
							</Placeholder>
						</Table.Cell>
						<Table.Cell>
							<Placeholder>
								<Placeholder.Line length='medium' />
								<Placeholder.Line length='medium' />
							</Placeholder>
						</Table.Cell>
						<Table.Cell>
							<Placeholder>
								<Placeholder.Line length='very long' />
								<Placeholder.Line length='full' />
							</Placeholder>
						</Table.Cell>
						<Table.Cell>
							<Placeholder>
								<Placeholder.Line length='medium' />
								<Placeholder.Line length='medium' />
							</Placeholder>
						</Table.Cell>
						<Table.Cell>
							<Placeholder>
								<Placeholder.Line length='short' />
								<Placeholder.Line length='short' />
							</Placeholder>
						</Table.Cell>
						<Table.Cell>
							<Placeholder>
								<Placeholder.Line length='medium' />
								<Placeholder.Line length='medium' />
							</Placeholder>
						</Table.Cell>
						<Table.Cell>
							<Placeholder>
								<Placeholder.Header image>
									<Placeholder.Line length='very short' />
									<Placeholder.Line length='very short' />
								</Placeholder.Header>
							</Placeholder>
						</Table.Cell>
					</Table.Row>
				);
			})}
		</Table.Body>
	);
}
//TODO
function buildTableBodyView(props) {
	const users = Array.from(props.itemList);
	return (
		<Table.Body>
			{_map(users, (user) => {
				const userID = user.DATA.uid;
				const userName = user.DATA.Name;
				let city = '-';
				let state = '-';
				let country = '-';
				if (user.REFERENCES.hasOwnProperty(user.DATA.DefaultAddress)) {
					city =
						user.REFERENCES[user.DATA.DefaultAddress].city || '-';
					state =
						user.REFERENCES[user.DATA.DefaultAddress].state || '-';
					country =
						user.REFERENCES[user.DATA.DefaultAddress].country ||
						'-';
				}
				const email = user.DATA.Email || '-';
				const phonenumber = user.DATA.MobileNumber || '-';

				return (
					<Table.Row
						key={userID}
						onClick={executeItemOpenAction.bind(
							this,
							userID,
							user.DATA.Category,
							props.eventContext
						)}>
						<Table.Cell>
							<span>{userName}</span>
						</Table.Cell>

						<Table.Cell>
							<span>{email}</span>
						</Table.Cell>
						<Table.Cell>
							<span>{phonenumber}</span>
						</Table.Cell>
						<Table.Cell>{city}</Table.Cell>
						<Table.Cell>{state}</Table.Cell>
						<Table.Cell>{country}</Table.Cell>
						<Table.Cell>
							<Dropdown
								size='mini'
								icon='caret down'
								button
								floating
								className='icon'
								direction='left'>
								<Dropdown.Menu>
									<Dropdown.Item
										icon='delete'
										text='Delete'
										onClick={deleteCustomer.bind(
											this,
											userID
										)}
									/>
								</Dropdown.Menu>
							</Dropdown>
						</Table.Cell>
					</Table.Row>
				);
			})}
		</Table.Body>
	);
}

function PaginationView(props) {
	const { pagination_activePage, pagination_totalPages, isLoading } = props;
	if (pagination_activePage && pagination_totalPages > 1 && !isLoading) {
		return (
			<Segment
				clearing
				basic
				className='orderTableFooter'
				textAlign='center'>
				<Pagination
					defaultActivePage={pagination_activePage}
					totalPages={pagination_totalPages}
					firstItem={null}
					lastItem={null}
					onPageChange={executePageAction.bind(
						this,
						props.eventContext
					)}
				/>
			</Segment>
		);
	} else {
		return null;
	}
}

function TableView(props) {
	//For EMPTY Filter Result
	let noResultsFound = false;
	if (!props.isLoading && props.itemList && props.itemList.length === 0) {
		noResultsFound = true;
	}
	//TODO
	if (noResultsFound) {
		return (
			<Container
				fluid
				style={{
					padding:
						'0rem 2.142857142857143rem 0rem 2.142857142857143rem',
					height: '100%',
					overflow: 'auto',
				}}>
				<Table singleLine className='orderTable'>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell width='2'>Name</Table.HeaderCell>
							<Table.HeaderCell width='4'>
								Email Address
							</Table.HeaderCell>
							<Table.HeaderCell width='3'>
								Mobile Number
							</Table.HeaderCell>
							<Table.HeaderCell width='4'>City</Table.HeaderCell>
							<Table.HeaderCell width='4'>State</Table.HeaderCell>
							<Table.HeaderCell width='4'>
								Country
							</Table.HeaderCell>
							<Table.HeaderCell width='1'>
								Action
							</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						<Table.Row>
							<Table.Cell
								colSpan='7'
								textAlign='center'
								style={{ borderRadius: '0.5rem' }}>
								No Records Found
							</Table.Cell>
						</Table.Row>
					</Table.Body>
				</Table>
			</Container>
		);
	} else {
		return (
			<Container
				fluid
				style={{
					padding:
						'0rem 2.142857142857143rem 0rem 2.142857142857143rem',
					height: '100%',
					overflow: 'auto',
				}}>
				<Table className='orderTable'>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell width='2'>Name</Table.HeaderCell>
							<Table.HeaderCell width='4'>
								Email Address
							</Table.HeaderCell>
							<Table.HeaderCell width='3'>
								Mobile Number
							</Table.HeaderCell>
							<Table.HeaderCell width='2'>City</Table.HeaderCell>
							<Table.HeaderCell width='2'>State</Table.HeaderCell>
							<Table.HeaderCell width='3'>
								Country
							</Table.HeaderCell>
							<Table.HeaderCell width='1'>
								Action
							</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					{props.isLoading && buildLoadingView(props)}
					{!props.isLoading && buildTableBodyView(props)}
				</Table>
				<PaginationView {...props} />
			</Container>
		);
	}
}

export default inject('storeFactory')(observer(TableView));
