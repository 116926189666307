import { format, parseISO } from 'date-fns';
import { enGB } from 'date-fns/locale';
import _find from 'lodash/find';
import _map from 'lodash/map';
import _times from 'lodash/times';
import { observable, runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import { DatePickerCalendar } from 'react-nice-dates';
import {
	Button,
	Container,
	Dropdown,
	Header,
	Icon,
	Label,
	Modal,
	Pagination,
	Placeholder,
	Segment,
	Table,
	Transition,
} from 'semantic-ui-react';
import { emitter, EVENTS } from '../../0-common/utils/EventEmitter';

const currentOrderActionInfo = observable({
	showOrderActionForm: false,
	orderActionKey: '',
	subjectIDorderActionLabel: '',
	subject: {},
});

const UpdateDeliveryDetailsInfo = observable({
	expectedDeliveryDate: null,
});

function setExpectedDeliveryDate(date) {
	UpdateDeliveryDetailsInfo.expectedDeliveryDate = date;
}

function getActionIconMap() {
	return {
		Cancel: <Icon name='times circle outline' />,
		UpdateDeliveryDetails: <Icon name='clipboard list' />,
		Dispatched: <Icon name='shipping fast' />,
		Delivered: <Icon name='check circle outline' />,
		RefundProcessed: <Icon name='check circle outline' />,
		Accept: <Icon name='thumbs up outline' />,
		Reject: <Icon name='thumbs down outline' />,
		ReturnReceived: <Icon name='check circle outline' />,
	};
}

function executeItemOpenAction(itemId, itemCategory, eventContext) {
	emitter.emit(
		EVENTS.ITEM.OPEN + eventContext,
		EVENTS.ITEM.OPEN,
		itemId,
		'ORDERS',
		itemCategory
	);
}

function executeOrderAction(actionValue, subject) {
	let patch = [];
	switch (actionValue) {
		case 'UpdateDeliveryDetails':
			const expectedDeliveryDate = moment(
				UpdateDeliveryDetailsInfo.expectedDeliveryDate
			)
				.startOf('day')
				.format();
			patch.push({
				op: 'add',
				path: '/ExpectedDeliveryDate',
				value: expectedDeliveryDate,
			});

		default:
			emitter.emit(
				EVENTS.WORKFLOW.ACTION.EXECUTE,
				actionValue,
				subject.DATA.uid,
				patch
			);
	}
	closeOrderAction();
}

function closeOrderAction() {
	runInAction('cancelOrderAction', () => {
		currentOrderActionInfo.showOrderActionForm = false;
		currentOrderActionInfo.orderActionKey = '';
		currentOrderActionInfo.orderActionLabel = '';
		currentOrderActionInfo.subject = {};
	});
}

function preExecuteOrderAction(actionValue, actionLabel, subject) {
	switch (actionValue) {
		case 'UpdateDeliveryDetails':
			runInAction('UpdateDeliveryDetails', () => {
				currentOrderActionInfo.showOrderActionForm = true;
				currentOrderActionInfo.orderActionKey = actionValue;
				currentOrderActionInfo.orderActionLabel = actionLabel;
				currentOrderActionInfo.subject = subject;
				if (subject.DATA.ExpectedDeliveryDate) {
					UpdateDeliveryDetailsInfo.expectedDeliveryDate = parseISO(
						subject.DATA.ExpectedDeliveryDate
					);
				}
			});
			break;

		default:
			emitter.emit(
				EVENTS.WORKFLOW.ACTION.EXECUTE,
				actionValue,
				subject.DATA.uid
			);
	}
}

function executePageAction(eventContext, e, { activePage }) {
	emitter.emit(EVENTS.PAGE.PAGINATE + eventContext, activePage);
}

function getUpdateDeliveryDetailsForm() {
	return (
		<Segment basic>
			<Header as='h4'>
				<Icon name='calendar alternate outline' />
				<Header.Content>
					Expected Delivery Date :{' '}
					{UpdateDeliveryDetailsInfo.expectedDeliveryDate
						? format(
								UpdateDeliveryDetailsInfo.expectedDeliveryDate,
								'dd MMM yyyy',
								{ locale: enGB }
						  )
						: ''}
				</Header.Content>
			</Header>
			<DatePickerCalendar
				date={UpdateDeliveryDetailsInfo.expectedDeliveryDate}
				onDateChange={setExpectedDeliveryDate}
				format='dd MMM yyyy'
				locale={enGB}
				minimumDate={new Date()}
			/>
		</Segment>
	);
}

function getOrderActionForm() {
	switch (currentOrderActionInfo.orderActionKey) {
		case 'UpdateDeliveryDetails':
			return getUpdateDeliveryDetailsForm();
	}
}

function renderOrderActionForm() {
	return (
		<Transition
			visible={currentOrderActionInfo.showOrderActionForm}
			animation='zoom'
			duration={500}>
			<Modal
				key='OrderActionForm'
				centered
				open={currentOrderActionInfo.showOrderActionForm}
				closeOnDimmerClick={false}
				size='tiny'>
				<Header as='h3'>
					{getActionIconMap()[currentOrderActionInfo.orderActionKey]}
					<Header.Content>
						{currentOrderActionInfo.orderActionLabel}
					</Header.Content>
				</Header>
				<Modal.Content>{getOrderActionForm()}</Modal.Content>
				<Modal.Actions>
					<Button
						primary
						content='Confirm'
						onClick={executeOrderAction.bind(
							null,
							currentOrderActionInfo.orderActionKey,
							currentOrderActionInfo.subject
						)}
					/>
					<Button content='Cancel' onClick={closeOrderAction} />
				</Modal.Actions>
			</Modal>
		</Transition>
	);
}

function buildLoadingView() {
	const count = 6;
	return (
		<Table.Body>
			{_times(count, (counter) => {
				return (
					<Table.Row key={counter}>
						<Table.Cell>
							<Placeholder>
								<Placeholder.Line length='medium' />
								<Placeholder.Line length='medium' />
							</Placeholder>
						</Table.Cell>
						<Table.Cell>
							<Placeholder>
								<Placeholder.Line length='medium' />
								<Placeholder.Line length='medium' />
							</Placeholder>
						</Table.Cell>
						<Table.Cell>
							<Placeholder>
								<Placeholder.Line length='very long' />
								<Placeholder.Line length='full' />
							</Placeholder>
						</Table.Cell>
						<Table.Cell>
							<Placeholder>
								<Placeholder.Line length='medium' />
								<Placeholder.Line length='medium' />
							</Placeholder>
						</Table.Cell>
						<Table.Cell>
							<Placeholder>
								<Placeholder.Line length='short' />
								<Placeholder.Line length='short' />
							</Placeholder>
						</Table.Cell>
						<Table.Cell>
							<Placeholder>
								<Placeholder.Line length='medium' />
								<Placeholder.Line length='medium' />
							</Placeholder>
						</Table.Cell>
						<Table.Cell>
							<Placeholder>
								<Placeholder.Header image>
									<Placeholder.Line length='very short' />
									<Placeholder.Line length='very short' />
								</Placeholder.Header>
							</Placeholder>
						</Table.Cell>
					</Table.Row>
				);
			})}
		</Table.Body>
	);
}

function buildTableBodyView(props) {
	const orders = Array.from(props.itemList);
	const currencyStore = props.storeFactory.CurrencyStore;
	return (
		<Table.Body>
			{_map(orders, (order) => {
				const orderID = order.DATA.uid;
				const orderSourceID = order.DATA.SourceID;
				const orderCategory = order.DATA.Category;
				const buyerID = order.DATA.Buyer;
				let buyerName = order.DATA.OrderAddressName || '';
				if (order.DATA.Buyer && order.DATA.Buyer.length > 0) {
					buyerName = order.REFERENCES[buyerID].Name || '';
				}

				const streetaddress =
					order.DATA.OrderAddressStreetAddress || '';
				const city = order.DATA.OrderAddressCity || '';
				const state = order.DATA.OrderAddressState || '';
				const pincode = order.DATA.OrderAddressPincode || '';

				const orderPlacedDate = moment(
					order.DATA.OrderPlacedDate
				).format('DD MMM YYYY');
				const deliveryAmount = order.DATA.OrderDeliveryAmount || 0;
				const orderTransactionCurrency =
					order.DATA.OrderTransactionCurrency;
				const orderAmount = currencyStore.formatCurrency(
					orderTransactionCurrency,
					order.DATA.OrderPayableAmount ||
						order.DATA.OrderAmount + deliveryAmount
				);

				const orderStatusID = order.DATA.OrderStatus[0];
				const orderStatusRef = _find(order.OPTIONS.OrderStatus, [
					'key',
					orderStatusID,
				]);
				let orderStatus = '';
				let orderStatusColor = 'grey';
				if (orderStatusRef) {
					orderStatus = orderStatusRef.text;
					switch (orderStatusRef.source) {
						case 'orderplaced':
						case 'ordershipped':
						case 'orderdelivered':
							orderStatusColor = 'green';
							break;

						case 'ordercancelled':
						case 'ordercancelrefunded':
							orderStatusColor = 'red';
							break;

						case 'orderreturnrequested':
						case 'orderreturnscheduled':
						case 'orderreturnrejected':
						case 'orderreturned':
						case 'orderreturnrefunded':
							orderStatusColor = 'orange';
							break;
					}
				}

				return (
					<Table.Row
						key={orderID}
						onClick={executeItemOpenAction.bind(
							this,
							orderID,
							orderCategory,
							props.eventContext
						)}>
						<Table.Cell>{orderSourceID}</Table.Cell>
						<Table.Cell>
							<span>{buyerName}</span>
						</Table.Cell>
						<Table.Cell>
							{streetaddress}, {city}, {state} - {pincode}{' '}
						</Table.Cell>
						<Table.Cell>{orderPlacedDate}</Table.Cell>
						<Table.Cell>{orderAmount}</Table.Cell>
						<Table.Cell>
							<Label
								style={{ display: 'inline-table' }}
								circular
								color={orderStatusColor}
								empty
								size='small'
							/>
							<span style={{ marginLeft: '0.5rem' }}>
								{orderStatus}
							</span>
						</Table.Cell>
						<Table.Cell>
							<Dropdown
								icon='caret down'
								button
								floating
								className='icon'
								direction='left'>
								<Dropdown.Menu>
									{_map(order.ACTIONS, (action) => {
										const actionIcon =
											getActionIconMap()[action.key];
										return (
											<Dropdown.Item
												icon={actionIcon}
												key={action.key}
												text={action.text}
												onClick={preExecuteOrderAction.bind(
													this,
													action.value,
													action.text,
													order
												)}
											/>
										);
									})}
								</Dropdown.Menu>
							</Dropdown>
						</Table.Cell>
					</Table.Row>
				);
			})}
			{renderOrderActionForm()}
		</Table.Body>
	);
}

function PaginationView(props) {
	const { pagination_activePage, pagination_totalPages, isLoading } = props;
	if (pagination_activePage && pagination_totalPages > 1 && !isLoading) {
		return (
			<Segment
				clearing
				basic
				className='orderTableFooter'
				textAlign='center'>
				<Pagination
					defaultActivePage={pagination_activePage}
					totalPages={pagination_totalPages}
					firstItem={null}
					lastItem={null}
					onPageChange={executePageAction.bind(
						this,
						props.eventContext
					)}
				/>
			</Segment>
		);
	} else {
		return null;
	}
}

function TableView(props) {
	//For EMPTY Filter Result
	let noResultsFound = false;
	if (!props.isLoading && props.itemList && props.itemList.length === 0) {
		noResultsFound = true;
	}

	if (noResultsFound) {
		return (
			<Container
				fluid
				style={{
					padding:
						'0rem 2.142857142857143rem 0rem 2.142857142857143rem',
					height: '100%',
					overflow: 'auto',
				}}>
				<Table singleLine className='orderTable'>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell width='3'>
								Order Id
							</Table.HeaderCell>
							<Table.HeaderCell width='2'>Name</Table.HeaderCell>
							<Table.HeaderCell width='4'>
								Address
							</Table.HeaderCell>
							<Table.HeaderCell width='2'>Date</Table.HeaderCell>
							<Table.HeaderCell width='2'>
								Amount
							</Table.HeaderCell>
							<Table.HeaderCell width='2'>
								Status
							</Table.HeaderCell>
							<Table.HeaderCell width='1'>
								Action
							</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						<Table.Row>
							<Table.Cell
								colSpan='7'
								textAlign='center'
								style={{ borderRadius: '0.5rem' }}>
								No Records Found
							</Table.Cell>
						</Table.Row>
					</Table.Body>
				</Table>
			</Container>
		);
	} else {
		return (
			<Container
				fluid
				style={{
					padding:
						'0rem 2.142857142857143rem 0rem 2.142857142857143rem',
					height: '100%',
					overflow: 'auto',
				}}>
				<Table className='orderTable'>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell width='3'>
								Order Id
							</Table.HeaderCell>
							<Table.HeaderCell width='2'>Name</Table.HeaderCell>
							<Table.HeaderCell width='4'>
								Address
							</Table.HeaderCell>
							<Table.HeaderCell width='2'>Date</Table.HeaderCell>
							<Table.HeaderCell width='2'>
								Amount
							</Table.HeaderCell>
							<Table.HeaderCell width='2'>
								Status
							</Table.HeaderCell>
							<Table.HeaderCell width='1'>
								Action
							</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					{props.isLoading && buildLoadingView(props)}
					{!props.isLoading && buildTableBodyView(props)}
				</Table>
				<PaginationView {...props} />
			</Container>
		);
	}
}

export default inject('storeFactory')(observer(TableView));
